<template>
    <div class="flex flex-col gap-5 my-5">
        <p v-if="tool_call.parameters.preamble">{{ tool_call.parameters.preamble }}</p>
        <ul class="flex gap-5 flex-col !ml-0">
            <template v-for="(point, idx) in tool_call.parameters.bullet_points" :key="idx">
                <Transition name="fade" appear>
                    <li
                        v-if="point.title"
                        :key="idx"
                        class="bg-white p-4 list-none rounded-xl flex gap-4 border"
                        :class="{ 'border-blue-400': selectedPoints.includes(point.title), 'cursor-pointer hover:border-blue-500': !coachedOn }"
                        @click="!coachedOn && check(point.title)"
                    >
                        <div class="flex items-center">
                            <svg class="item-circle" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path v-if="!selectedPoints.includes(point.title)" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path
                                    v-if="selectedPoints.includes(point.title)"
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                />
                            </svg>
                        </div>
                        <div class="flex-col gap-4">
                            <h3 class="text-xl font-bold">{{ point.title }}</h3>
                            <p>{{ point.content }}</p>
                        </div>
                    </li>
                </Transition>
            </template>
        </ul>
        <div>
            <button
                class="py-2 px-4 rounded-full text-sm"
                :disabled="coachedOn !== null"
                :class="coachedOn !== null ? 'bg-valence-grey-50' : 'bg-valence-pink-50 cursor-pointer'"
                @click="coachMe()"
            >
                Coach me on this
                <span
                    class="inline-block h-5 w-5 ml-2 rounded-full rounded-full relative translate-y-1"
                    :class="coachedOn !== null ? 'bg-valence-grey-600' : 'bg-valence-pink-600'"
                >
                    <i class="bi bi-arrow-right-short text-lg text-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></i>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CoachableBulletPointsMultiselect",
    components: {},
    props: {
        tool_call: {
            type: Object,
            default() {
                return {};
            },
        },
        messageId: {
            type: Number,
            default: 0,
        },
        lineIdx: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            selectedPoints: this.tool_call?.tool_state?.coach_me.split(", ") || [],
            coachedOn: this.tool_call?.tool_state?.coach_me || null,
        };
    },
    methods: {
        check(title) {
            if (this.selectedPoints.includes(title)) {
                this.selectedPoints = this.selectedPoints.filter((point) => point !== title);
            } else {
                this.selectedPoints.push(title);
            }
        },
        coachMe() {
            const content = this.selectedPoints.join(", ");
            this.coachedOn = content;
            this.emitter.emit("coachable_bullet_points_coach_me", {
                action_params: { coach_me: { content } },
                message_id: this.messageId,
                lineIdx: this.lineIdx,
                coaching_session_id: document.getElementById("app").dataset.coachingSessionId,
                multiSelect: true,
            });
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1000 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
