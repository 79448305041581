<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99992 15.1688C11.6818 15.1688 14.6666 12.184 14.6666 8.50212C14.6666 4.82022 11.6818 1.83545 7.99992 1.83545C4.31802 1.83545 1.33325 4.82022 1.33325 8.50212C1.33325 12.184 4.31802 15.1688 7.99992 15.1688Z"
            stroke="#393939"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M8 11.8354H8.00667" stroke="#393939" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M6.06006 6.50184C6.21679 6.05629 6.52616 5.68058 6.93336 5.44127C7.34056 5.20195 7.81932 5.11447 8.28484 5.19432C8.75036 5.27417 9.1726 5.51619 9.47678 5.87753C9.78095 6.23887 9.94743 6.69619 9.94672 7.16851C9.94672 8.50184 7.94672 9.16851 7.94672 9.16851"
            stroke="#393939"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
