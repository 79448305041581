<template>
    <div
        v-if="!data.action_state?.submitted && !data.action_state?.dismissed"
        class="w-full gap-8 mt-8"
        :class="data.action_params.answers.length > 1 ? 'grid grid-cols-2' : 'flex justify-end'"
    >
        <template v-for="(answer, idx) in data.action_params.answers" :key="idx">
            <BaseActionCard :title="answer" @click="() => chooseAnswer(answer)">
                <span class="font-semibold">{{ answer }}</span>
            </BaseActionCard>
        </template>
    </div>
</template>

<script setup>
import BaseActionCard from "~vue/components/BaseActionCard.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject, onMounted } from "vue";

const { emitter } = inject("globalProperties");

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    messageId: {
        type: Number,
        default: 0,
    },
    lineIdx: {
        type: Number,
        default: 0,
    },
});

onMounted(() => {
    logUserInteraction("answer_presented", { chat_message_id: props.messageId });
});

const chooseAnswer = (answer) => {
    const action_data = {
        answer: answer,
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        block_id: props.data.action_params.block_id,
        messageId: props.messageId,
        action_state: {
            dismissed: false,
            submitted: true,
        },
    };
    emitter.emit("choose_answer", action_data);
    logUserInteraction("answer_chosen");
};
</script>
