<template>
    <dialog id="action-items-email-dialog" ref="dialogElement" class="bg-white md:p-8 p-4 rounded-xl">
        <div class="flex flex-col gap-4 text-center w-full md:w-[600px]">
            <div class="text-xl font-bold">Your action plan from today&apos;s session</div>
            <textarea
                id="action-items-email-summary"
                v-model="editedSummary"
                class="border rounded-sm border-gray-200 resize-none text-sm p-2 w-full whitespace-pre-line"
                rows="10"
                name="summary"
                placeholder="What do you think would make our conversations more effective?"
                autofocus
                required
                autocomplete="off"
            ></textarea>
            <div v-if="error === errors.VALIDATION_ERROR" class="text-xs text-red-400">Summary cannot be empty.</div>
            <div class="mt-4">
                <button type="button" autofocus class="rounded-xl text-white bg-valence-pink-600 hover:bg-valence-pink-600/80 font-bold text-sm py-3 px-6" @click="send">
                    Send summary
                </button>
            </div>
            <button type="button" class="text-xs underline text-valence-grey-800/75 hover:text-valence-grey-800/50" @click="dismiss">Dismiss</button>
        </div>
    </dialog>
</template>

<script>
import { ref } from "vue";

const ERRORS = {
    VALIDATION_ERROR: "validation_error",
};

export default {
    name: "ChatActionItemsEmailDialog",
    emits: ["complete"],
    props: {
        summary: String(""),
        actionItems: Object({}),
    },
    setup(props) {
        return {
            dialogElement: ref(null),
            editedSummary: ref(props.summary),
            error: ref(null),
            actionItems: ref(props.actionItems),
        };
    },
    computed: {
        errors() {
            return ERRORS;
        },
    },
    methods: {
        dismiss() {
            this.dialogElement.close();
        },
        send() {
            if (this.editedSummary.length === 0) {
                this.error = ERRORS.VALIDATION_ERROR;
                return;
            }
            this.$emit("complete", this.editedSummary, this.actionItems);
        },
        open() {
            this.dialogElement.showModal();
        },
    },
};
</script>

<style>
dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
