<template>
    <div class="flex flex-row w-full max-w-[650px] mx-auto gap-4 relative px-4 sm:px-6">
        <div v-if="actionIsActionable && !dismissed" class="rounded-xl w-full leading-[0] p-4 scroll-none bg-white flex sm:flex-row gap-2 sm:gap-4 py-2 flex-col items-center">
            <div class="flex-grow text-center sm:text-left">
                <h3 class="text-sm font-semibold py-2">Would you like to follow up on this?</h3>
            </div>
            <div class="flex flex-col sm:flex-row items-center gap-2">
                <button type="button" class="text-sm underline cursor-pointer pr-2" @click="handleSkip">Skip</button>
                <button
                    type="button"
                    class="py-2 px-4 text-xs md:text-sm font-semibold leading-none text-white bg-valence-light-blue-400 hover:bg-valence-light-blue-600 rounded-xl sm:w-auto"
                    @click="handleScheduleFollowup"
                >
                    Schedule
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { logUserInteraction } from "~vue/utils/logUtils";

import { CHAT_EVENT } from "./events";

export default {
    name: "ChatActionFollowupInChat",
    props: {
        data: Object({}),
    },
    data() {
        return {
            actionIsActionable: !this.data.line.action_params.event_at_confirmed,
            dismissed: this.data.line.action_state.dismissed,
        };
    },
    mounted() {
        logUserInteraction("followup_presented", { chat_message_id: this.data.messageId });
    },
    methods: {
        handleScheduleFollowup() {
            this.actionIsActionable = false;
            this.emitter.emit(CHAT_EVENT.OPEN_EXIT_INTENT_DIALOG);
            logUserInteraction("followup_scheduled");
        },
        handleSkip() {
            if (this.data.line) {
                const action_data = {
                    message_id: this.data.messageId,
                    lineIdx: this.data.lineIdx,
                    action_params: this.data.line.action_params,
                    action_state: {
                        dismissed: true, // in the case of event_identifier (followup) actions, I guess it makes no difference?
                    },
                };
                this.emitter.emit("followup_update", action_data);
            }

            this.actionIsActionable = false;
            this.dismissed = true;
        },
    },
};
</script>
