<template>
    <dialog id="session-summary-dialog" ref="dialogElement" class="focus-visible:outline-0 bg-white md:p-16 p-4 rounded-xl text-valence-grey-800 relative">
        <button :disabled="isSubmittingFollowUp" type="button" class="absolute top-2 right-2 p-3" @click="dismiss">
            <i class="bi bi-x text-xl text-valence-grey-600 hover:text-valence-grey-600/80" :class="{ 'text-valence-grey-600/80': isSubmittingFollowUp }"></i>
        </button>
        <div class="stepper-container w-[350px] md:w-[600px]">
            <div class="stepper-content">
                <div v-if="isLoadingSteps && steps.length === 0" class="flex flex-col gap-6 text-center max-w-prose">
                    <div class="flex flex-col gap-2">
                        <div class="text-xl font-bold">Just a sec...</div>
                        <img :src="$loadingImageSrc" class="h-12 mx-auto block" alt="" />
                    </div>
                </div>

                <div v-if="currentStep?.step === ALL_STEPS.ACTION_ITEMS">
                    <div class="text-left">
                        <template v-if="actionItemsAction">
                            <div class="text-xl font-bold text-center mb-4">Here's a recap</div>
                            <div v-if="showInferences">
                                <span class="font-semibold grow text-left uppercase">What I've Learned</span>
                                <div v-for="inference in profileInferences" :key="inference.id">
                                    <div class="relative w-full my-2 bg-valence-grey-50 rounded-xl p-4 leading-none items-start">
                                        <p class="text-md font-semibold mb-2">Your {{ inference.profile_question_key }}</p>
                                        <p class="text-md text-valence-grey-400">{{ inference.value }}</p>
                                    </div>
                                    <!-- <li class="max-w-xs mx-auto text-md text-gray-500">{{ inference.profile_question_key }}: {{ inference.value }}</li> -->
                                </div>
                            </div>

                            <div class="mt-5 mb-2">
                                <span class="grow text-left font-semibold text-base uppercase">Your Action Items</span>
                            </div>
                            <ActionItemsList
                                :action-items="actionItems"
                                :message-id="actionItemsAction.message.chat_message_id"
                                :line-idx="actionItemsAction.lineIdx"
                                :data="actionItemsAction.line"
                                class="text-gray-500"
                            />
                            <div class="flex justify-center">
                                <button
                                    v-if="!actionItemsAction.line.action_params.email_sent"
                                    type="button"
                                    class="py-3 leading-none rounded-xl bg-valence-pink-600 hover:bg-valence-pink-600/80 text-white h-auto w-80 text-sm font-semibold mt-10"
                                    @click="openActionItemsEmailDialog"
                                >
                                    Send me a summary
                                </button>
                            </div>

                            <ChatActionItemsEmailDialog
                                v-if="!actionItemsAction.line.action_params.email_sent"
                                ref="actionItemsEmailDialog"
                                :summary="actionItemsAction.line.action_params.email_summary"
                                :action-items="actionItemsAction"
                                @complete="sendActionItemsEmail"
                            />
                        </template>
                    </div>
                </div>

                <div v-else-if="currentStep?.step === ALL_STEPS.FOLLOW_UP">
                    <div class="flex flex-col gap-6 text-center max-w-prose">
                        <div class="flex flex-col gap-2">
                            <div class="text-xl font-bold">Let's plan for next time</div>
                            <div v-if="isLoadingFollowUpCopy">
                                <img :src="$loadingImageSrc" class="h-12 mx-auto block" alt="" />
                            </div>
                            <p v-else class="max-w-xs mx-auto text-base" v-text="followUpCopy"></p>
                        </div>
                        <p v-if="followUpSubmitError" class="max-w-xs text-sm text-red-400">Sorry, I&apos;m having trouble scheduling our follow-up. Please try again later.</p>
                        <div v-if="!isLoadingFollowUpCopy" class="flex flex-col gap-4">
                            <ScheduleFollowUp :date="followUpInputDate" :recurring="isFollowUpRecurring" @date-update="onDateUpdate" @recurring-update="onRecurringUpdate" />
                            <button
                                :disabled="isSubmittingFollowUp"
                                type="button"
                                autofocus
                                class="w-full rounded-xl text-white bg-valence-pink-600 hover:bg-valence-pink-600/80 font-bold text-sm py-3 px-6 leading-none"
                                :class="{ 'bg-valence-pink-600/80': isSubmittingFollowUp }"
                                @click="scheduleFollowUp"
                            >
                                <div v-if="isSubmittingFollowUp || isLoadingSteps" class="h-5 w-5 inline-block mx-auto">
                                    <LoadingSpinner />
                                </div>
                                <template v-else>Schedule</template>
                            </button>
                        </div>
                        <button
                            v-if="!isLoadingFollowUpCopy"
                            :disabled="isSubmittingFollowUp"
                            type="button"
                            class="text-xs underline text-valence-grey-800/75 hover:text-valence-grey-800/50"
                            :class="{ 'text-valence-grey-800/50': isSubmittingFollowUp }"
                            @click="dismiss"
                        >
                            Never mind, I can keep chatting now
                        </button>
                    </div>
                </div>

                <div v-else-if="currentStep?.step === ALL_STEPS.FUTURE_TOPICS">
                    <span class="font-bold grow text-center text-xl">Here are some future topics for us to check in on</span>
                    <ul class="flex gap-5 flex-col !ml-0 mt-10">
                        <template v-for="(topic, idx) in suggestedTopics">
                            <li
                                v-if="topic.topic_name"
                                :key="idx"
                                class="bg-white p-4 list-none rounded-xl flex gap-4 border text-left"
                                :class="{
                                    'border-blue-400 bg-blue-50': selectedTopics.includes(topic.topic_name),
                                    'cursor-pointer hover:border-blue-400 border-blue-200': !selectedTopics.includes(topic.topic_name),
                                }"
                                @click="toggleSelectedTopic(topic.topic_name)"
                            >
                                <div class="flex items-center">
                                    <svg class="item-circle" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path v-if="!selectedTopics.includes(topic.topic_name)" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path
                                            v-if="selectedTopics.includes(topic.topic_name)"
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                        />
                                    </svg>
                                </div>
                                <div class="flex-col gap-4">
                                    <h3 class="text-l">{{ topic.topic_name }}</h3>
                                </div>
                            </li>
                        </template>
                    </ul>

                    <button
                        type="button"
                        class="py-3 leading-none rounded-xl bg-valence-pink-600 hover:bg-valence-pink-600/80 text-white h-auto w-full text-sm font-semibold mt-10"
                        @click="goToNextStep"
                    >
                        Save topics for later
                    </button>
                </div>

                <div v-else-if="currentStep?.step === ALL_STEPS.SUMMARY_COMPLETION">
                    <div class="text-center mb-6">
                        <template v-if="summaryCompletionCopy">
                            <span class="font-bold grow text-left text-xl">{{ summaryCompletionHeader }}</span>
                            <p class="max-w-xs mx-auto text-gray-500 mt-6" v-text="summaryCompletionCopy"></p>
                        </template>
                    </div>
                    <button
                        type="button"
                        class="py-3 leading-none rounded-xl bg-valence-pink-600 hover:bg-valence-pink-600/80 text-white h-auto w-full text-sm font-semibold mt-10"
                        @click="dismiss"
                    >
                        Close
                    </button>
                </div>

                <div v-if="stepCount > 1 && currentStepIndex < stepCount - 1" class="stepper-progress">
                    <span v-for="step in steps" :key="step" :class="{ active: step.step <= currentStep.step }"></span>
                </div>

                <div class="flex flex-row w-full justify-between py-4">
                    <div>
                        <button
                            v-if="currentStepIndex > firstIncompleteStepIndex"
                            class="text-valence-grey-800/75 hover:text-valence-grey-800/50 pr-2"
                            @click="goToPreviousSkippedStep"
                        >
                            <i class="bi bi-chevron-left"></i>
                            Back
                        </button>
                    </div>
                    <div class="h-5">
                        <div v-if="isLoadingSteps && !isLoadingFollowUpCopy" class="h-5 w-5">
                            <LoadingSpinner />
                        </div>
                        <div v-else>
                            <button v-if="currentStepIndex < stepCount - 1" type="button" class="text-valence-grey-800/75 hover:text-valence-grey-800/50 pl-2" @click="skipStep">
                                Skip
                                <i class="bi bi-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script>
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { DateTime } from "luxon";
import { ref } from "vue";

import { nextWorkday, roundToFifteenMinuteIncrement, roundToNearest15 } from "../dateUtils.js";
import ActionItemsList from "./ActionItemsList.vue";
import ChatActionItemsEmailDialog from "./ChatActionItemsEmailDialog.vue";
import { ACTION, pluckAction } from "./chatActions.js";
import { CHAT_EVENT } from "./events.js";
import LoadingSpinner from "./icons/LoadingSpinner.vue";
import ScheduleFollowUp from "./ScheduleFollowUp.vue";

export default {
    name: "SessionSummary",

    components: {
        ScheduleFollowUp,
        LoadingSpinner,
        ActionItemsList,
        ChatActionItemsEmailDialog,
    },

    props: {
        messages: {
            type: Array,
            default: () => [],
        },
        coachingSessionId: {
            type: String,
            default: () => "",
        },
    },

    data() {
        const ALL_STEPS = {
            FOLLOW_UP: 0,
            ACTION_ITEMS: 1,
            FUTURE_TOPICS: 2,
            SUMMARY_COMPLETION: 3,
        };

        return {
            ALL_STEPS,
            steps: ref([]),
            currentStepIndex: ref(0),

            isLoadingSteps: ref(false),

            followUpDate: nextWorkday(roundToNearest15(DateTime.now().plus({ days: 3 }))).toJSDate(),
            localIsRecurring: ref(true),
            isLoadingFollowUpCopy: ref(false),
            isSubmittingFollowUp: ref(false),
            followUpSubmitError: ref(null),
            generatedFollowUpCopy: ref(null),
            isFollowUpScheduled: ref(false),

            profileInferences: ref(null),
            isActionItemsEmailSent: ref(false),

            selectedTopics: [],
        };
    },

    computed: {
        stepCount() {
            return this.steps.length;
        },
        currentStep() {
            return this.steps[this.currentStepIndex];
        },
        firstIncompleteStepIndex() {
            let index = 0;
            while (this.steps[index]?.isComplete) {
                index++;
            }
            return index;
        },

        actionItemsAction() {
            const action = pluckAction(this.messages, ACTION.ACTION_ITEMS);
            return action ?? null;
        },
        actionItems() {
            return this.actionItemsAction?.line?.action_params?.action_items ?? [];
        },
        showInferences() {
            return this.profileInferences && this.profileInferences.length > 0;
        },

        followUpInputDate() {
            if (this.followUpAction) {
                const dateString = this.followUpAction.line.action_params.event_at_confirmed ?? this.followUpAction.line.action_params.event_at_guessed;
                return new Date(dateString);
            }

            return this.followUpDate;
        },
        isFollowUpRecurring() {
            if (this.followUpAction) {
                return this.followUpAction.line.action_params.recurring ?? true;
            }

            return this.localIsRecurring;
        },
        followUpCopy() {
            if (this.generatedFollowUpCopy) {
                return this.generatedFollowUpCopy;
            }

            if (this.followUpAction && this.followUpAction.line.action_params.invite_description) {
                return this.followUpAction.line.action_params.invite_description;
            }

            return "Let's schedule some time to continue our conversation.";
        },
        followUpAction() {
            return pluckAction(this.messages, ACTION.FOLLOW_UP, true);
        },
        followUpDayOfWeek() {
            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            return daysOfWeek[this.followUpDate.getDay()];
        },

        suggestedTopics() {
            const suggestedTopics = pluckAction(this.messages, ACTION.SUGGESTED_TOPICS);
            return suggestedTopics?.line?.action_params?.suggested_topics ?? [];
        },

        summaryCompletionHeader() {
            if (this.$userDetails.value.first_name) {
                return `Nice work, ${this.$userDetails.value.first_name}!`;
            } else {
                return "Nice work!";
            }
        },
        summaryCompletionCopy() {
            const email = this.$userDetails.value.email;
            if (email && this.isActionItemsEmailSent && this.isFollowUpScheduled) {
                return `I've sent a summary to ${email}. Looking forward to our next check-in on ${this.followUpDayOfWeek}.`;
            } else if (this.isFollowUpScheduled && !this.isActionItemsEmailSent) {
                return `Looking forward to our next check-in on ${this.followUpDayOfWeek}.`;
            } else if (this.isActionItemsEmailSent && !this.isFollowUpScheduled) {
                return `I've sent a summary to ${email}. I'll follow up with you in the coming days to check-in on your week. Talk soon!`;
            } else {
                return "I'll follow up with you in the coming days to check-in on your week. Talk soon!";
            }
        },
    },

    mounted() {
        if (this.$refs.dialogElement) {
            this.$refs.dialogElement.addEventListener("cancel", this.dismiss);
        }

        this.emitter.on(CHAT_EVENT.OPEN_SESSION_SUMMARY, this.open);
    },

    unmounted() {
        if (this.$refs.dialogElement) {
            this.$refs.dialogElement.removeEventListener("cancel", this.dismiss);
        }

        this.emitter.off(CHAT_EVENT.OPEN_SESSION_SUMMARY, this.open);
    },

    methods: {
        open() {
            logUserInteraction("session_summary_viewed");

            this.resetFollowUpDate();
            this.followUpSubmitError = null;
            this.generateFollowUpCopy();

            this.isLoadingSteps = true;
            this.initSteps();
            this.currentStepIndex = this.firstIncompleteStepIndex;

            this.$sendEvent("wrap_up_chat").finally(() => {
                this.isLoadingSteps = false;
                this.initSteps();
                this.getProfileInferences();
            });

            this.$refs.dialogElement.showModal();
        },
        dismiss() {
            window.sessionStorage.setItem("session-summary", JSON.stringify({ seen: true }));
            this.$refs.dialogElement.close();
        },

        initSteps() {
            const newSteps = [];

            if (!this.followUpAction) {
                newSteps.push({
                    step: this.ALL_STEPS.FOLLOW_UP,
                    isComplete: false,
                });
            }

            if (this.isLoadingSteps) {
                // If we're still loading, we can only show the follow-up slide for now
                this.steps = newSteps;
                return;
            }

            if (this.actionItems.length > 0 && !this.actionItemsAction.line.action_params.email_sent) {
                newSteps.push({
                    step: this.ALL_STEPS.ACTION_ITEMS,
                    isComplete: false,
                });
            }
            if (this.suggestedTopics.length > 0) {
                newSteps.push({
                    step: this.ALL_STEPS.FUTURE_TOPICS,
                    isComplete: false,
                });
            }
            newSteps.push({
                step: this.ALL_STEPS.SUMMARY_COMPLETION,
            });

            this.steps = newSteps;
        },
        skipStep() {
            this.currentStepIndex++;
            while (this.steps[this.currentStepIndex].isComplete) {
                this.currentStepIndex++;
            }
        },
        goToNextStep() {
            this.steps[this.currentStepIndex].isComplete = true;
            while (this.steps[this.currentStepIndex].isComplete) {
                this.currentStepIndex++;
            }
        },
        goToPreviousSkippedStep() {
            let index = this.currentStepIndex - 1;
            while (index >= 0 && this.steps[index].isComplete) {
                index--;
            }
            if (index >= 0) {
                this.currentStepIndex = index;
            }
        },

        openActionItemsEmailDialog() {
            this.$refs.actionItemsEmailDialog.open();
        },
        sendActionItemsEmail(editedSummary, actionItemsAction) {
            const action_data = {
                subaction: "send_email_summary",
                email_summary: editedSummary,
                message_id: actionItemsAction.message.chat_message_id,
                lineIdx: actionItemsAction.lineIdx,
                action_params: {
                    ...actionItemsAction.line.action_params,
                    email_sent: true,
                },
                action_state: {
                    submitted: false,
                },
            };
            this.$refs.actionItemsEmailDialog.dismiss();
            this.emitter.emit("action_items", action_data);
            logUserInteraction("action_items_email_sent");
            this.isActionItemsEmailSent = true;
            this.goToNextStep();
        },

        async generateFollowUpCopy() {
            this.isLoadingFollowUpCopy = true;
            try {
                const response = await this.$sendEvent("generate_exit_follow_up_copy");
                this.generatedFollowUpCopy = response.payload;
            } finally {
                this.isLoadingFollowUpCopy = false;
            }
        },
        resetFollowUpDate() {
            this.followUpDate = new Date(Date.now());
            this.followUpDate.setDate(this.followUpDate.getDate() + 1);
            roundToFifteenMinuteIncrement(this.followUpDate);
        },
        onDateUpdate(newDate) {
            this.followUpDate = newDate;
        },
        onRecurringUpdate() {
            this.localIsRecurring = !this.localIsRecurring;
        },
        async scheduleFollowUp() {
            /* If the follow-up action exists in the current chat, the follow-up
             * is scheduled through the action's handler so that the widget
             * state is synchronized. Otherwise, the follow-up is scheduled
             * ad-hoc.
             */
            try {
                this.followUpSubmitError = null;
                this.isSubmittingFollowUp = true;
                if (this.followUpAction) {
                    const confirmedTime = this.followUpAction.line.action_params.event_at_confirmed ?? this.followUpAction.line.action_params.event_at_guessed;

                    const action_data = {
                        message_id: this.followUpAction.message.chat_message_id,
                        lineIdx: this.followUpAction.lineIdx,
                        action_params: {
                            calendar_event_id: this.followUpAction.line.action_params.calendar_event_id,
                            invite_description: this.followUpCopy,
                            event_at_confirmed: confirmedTime,
                            recurring: this.isFollowUpRecurring,
                        },
                        action_state: {
                            submitted: true,
                        },
                    };
                    this.emitter.emit("choose_followup", action_data);
                } else {
                    await this.$sendEvent("schedule_follow_up", {
                        event_description: this.followUpCopy,
                        event_at_confirmed: DateTime.fromJSDate(this.followUpDate).toISO(),
                        recurring: this.isFollowUpRecurring,
                    });
                }
                logUserInteraction("session_summary_followup_scheduled");
                this.isFollowUpScheduled = true;
                this.goToNextStep();
            } catch (e) {
                this.followUpSubmitError = e;
                if ("Sentry" in window) {
                    window.Sentry.captureException(e);
                }
            } finally {
                this.isSubmittingFollowUp = false;
            }
        },

        async getProfileInferences() {
            if (!this.coachingSessionId) {
                return;
            }
            try {
                const response = await fetch(`/accounts/profile-data/cs/${this.coachingSessionId}/`, {
                    method: "GET",
                    credentials: "include",
                });
                this.profileInferences = await response.json();
            } catch (e) {
                console.error(e);
            }
        },
        toggleSelectedTopic(topic) {
            if (this.selectedTopics.includes(topic)) {
                this.selectedTopics = this.selectedTopics.filter((t) => t !== topic);
            } else {
                this.selectedTopics.push(topic);
            }
        },
    },
};
</script>

<style scoped type="postcss">
dialog#session-summary-dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}

.stepper-container {
    margin: auto;
    padding: 20px;
    text-align: center;
    background-color: #fff;
}

.stepper-content {
    margin-bottom: 20px;
}

.stepper-progress {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.stepper-progress span {
    width: 30px;
    /* Increased width to make it a line */
    height: 5px;
    /* Decreased height to make it a line */
    margin: 0 5px;
    border-radius: 2px;
    /* Slight rounding of the edges */
    background-color: #ccc;
}

.stepper-progress .active {
    @apply bg-valence-pink-600;
}
</style>
