<template>
    <div class="relative inline-block text-left w-full">
        <div class="flex flex-row-reverse -mb-10">
            <div class="w-32 h-32 right-4 top-4">
                <CoachingModeMarble />
            </div>
        </div>
        <button type="button" class="text-left items-start w-full rounded-md px-4 py-2 text-md font-medium text-gray-700" @click="toggleDropdown">
            <span class="text-xs text-gray-400 uppercase font-light tracking-wide w-full">Coaching Mode</span>
            <div class="flex items-center w-full gap-2">
                <span class="font-extrabold">{{ currentCoachingMode }}</span>
                <i class="bi bi-chevron-down h-5 w-5"></i>
            </div>
        </button>

        <div v-if="isOpen" class="origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a
                    v-for="option in options"
                    :key="option.mode"
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    @click="selectCoachingMode(option.mode)"
                >
                    {{ option.mode }}
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useChatStore } from "~vue/stores/chatStore";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";

import CoachingModeMarble from "./CoachingModeMarble.vue";

const { emitter } = inject("globalProperties");

// Refs
const isOpen = ref(false);
const options = ref([]);

const currentCoachingMode = computed(() => useChatStore.coachingMode);

// Methods
function toggleDropdown() {
    isOpen.value = !isOpen.value;
}

function selectCoachingMode(data) {
    if (data?.detail?.data?.coaching_mode) {
        // Triggers when the coaching mode switches automatically
        useChatStore.setCoachingMode(data.detail.data.coaching_mode);
    } else {
        // Triggers when a user manually switches the coaching mode
        const oldCoachingMode = useChatStore.coachingMode;
        useChatStore.setCoachingMode(data);
        const newCoachingMode = data;
        const switchMessage = options.value.find((item) => item.mode === newCoachingMode)?.switch_message;
        emitter.emit("user_switched_coaching_mode", { oldCoachingMode, newCoachingMode, switchMessage });
    }
    isOpen.value = false;
}

const getOptions = async () => {
    try {
        const response = await fetch("/coach/coaching_mode", {
            method: "GET",
            credentials: "include",
        });
        const data = await response.json();
        options.value = data.coaching_modes;
        useChatStore.setCoachingMode(data.current_coaching_mode);
        return data;
    } catch (error) {
        if ("Sentry" in window) {
            window.Sentry.captureException(new Error("Failed to get Profile Notifications."));
        }
        return 0;
    }
};

onMounted(() => {
    getOptions();
    document.addEventListener("coaching_mode_updated", selectCoachingMode);
});

onUnmounted(() => {
    document.removeEventListener("coaching_mode_updated", selectCoachingMode);
});
</script>
