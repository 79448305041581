<template>
    <dialog ref="dialogElement" class="rounded-[20px] bg-white sm:max-w-[600px] p-5 sm:p-10 sm:mx-auto">
        <div class="flex flex-col gap-4 sm:gap-6">
            <i class="bi bi-flower3 text-[80px] sm:text-[102px] leading-none self-center"></i>
            <div class="font-bold text-xl sm:text-2xl">A peek into my thought process.</div>
            <div v-if="hasError">Sorry, I&apos;m having trouble providing an explanation. Please try again later.</div>
            <div v-else-if="isLoading">
                <img :src="$loadingImageSrc" class="h-12 mx-auto block" alt="" />
            </div>
            <div v-else-if="!!explanation" class="text-sm sm:text-base sm:leading-relaxed" v-html="explanation"></div>
            <button class="self-start bg-valence-pink py-3 sm:py-3.5 px-20 text-white rounded-full font-bold text-xs sm:text-sm mt-3" @click="close">Close</button>
        </div>
    </dialog>
</template>

<script setup>
import { ACTION, lineIsAction } from "~vue/chatActions";
import { CHAT_EVENT } from "~vue/events";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { computed, defineProps, inject, onMounted, onUnmounted, ref, watch } from "vue";

const props = defineProps({
    messages: Array([]),
});

const { emitter, $sendEvent, $loadingImageSrc } = inject("globalProperties");

const messageId = ref(null);
const isLoading = ref(false);
const hasError = ref(false);
const dialogElement = ref(null);

function open(msgId) {
    messageId.value = msgId;
    if (dialogElement.value) {
        dialogElement.value.showModal();
    }
}

function close() {
    messageId.value = null;
    if (dialogElement.value) {
        dialogElement.value.close();
    }
}

onMounted(() => {
    if (dialogElement.value) {
        dialogElement.value.addEventListener("cancel", close);
    }
    emitter.on(CHAT_EVENT.OPEN_THOUGHT_PROCESS_DIALOG, open);
});

onUnmounted(() => {
    if (dialogElement.value) {
        dialogElement.value.removeEventListener("cancel", close);
    }
    emitter.off(CHAT_EVENT.OPEN_THOUGHT_PROCESS_DIALOG, open);
});

const message = computed(() => {
    if (!messageId.value) {
        return null;
    }
    const result = props.messages.find((m) => m.chat_message_id === messageId.value);
    return result;
});

const explanation = computed(() => {
    if (!message.value) {
        return null;
    }
    const explanationLine = message.value.lines.find((line) => lineIsAction(line) && line.action_name === ACTION.LLM_EXPLANATION);
    if (!explanationLine) {
        return null;
    }
    const content = explanationLine.action_params?.content ?? "";
    return DOMPurify.sanitize(marked.parse(content, { headerIds: false, mangle: false }));
});

watch(message, (newMessage) => {
    if (!newMessage) {
        return;
    }
    const explanationLine = newMessage.lines.find((line) => lineIsAction(line) && line.action_name === ACTION.LLM_EXPLANATION);
    if (explanationLine) {
        isLoading.value = false;
    } else {
        hasError.value = false;
        isLoading.value = true;
        const payload = {
            chat_message_id: newMessage.chat_message_id,
        };
        $sendEvent("explain_llm_response", payload).catch((error) => {
            console.error("Failed to send explain_llm_response event", error);
            hasError.value = true;
        });
    }
});
</script>
