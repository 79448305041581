<template>
    <div v-if="hasPendingTasks" class="w-full items-center gap-3 align-self-end p-5 text-sm border-t border-t-gray-100">
        <button type="button" class="flex justify-between items-center w-full" @click="$emit('toggle')">
            <span class="font-bold grow text-left">Let&apos;s get started!</span>
            <div class="flex items-center space-x-2">
                <span v-if="open" class="inline-flex items-center justify-center w-6 h-6 text-xl text-valence-grey-800 hover:text-valence-grey-800/80">
                    <i class="bi bi-dash"></i>
                </span>
                <template v-else>
                    <span
                        class="inline-flex items-center justify-center w-6 h-6 text-sm p-0 font-bold text-white bg-valence-light-blue-400 rounded"
                        v-text="pendingTasks.length"
                    ></span>
                    <span class="inline-flex items-center justify-center w-6 h-6 text-xl text-valence-grey-800 hover:text-valence-grey-800/80"><i class="bi bi-plus"></i></span>
                </template>
            </div>
        </button>
        <template v-if="open">
            <p class="mt-2">Use these tips to get the most out of our time together</p>
            <div>
                <template v-for="task in checklistData" :key="task.id">
                    <div class="mt-4 tasks-start">
                        <div v-if="isTaskComplete(task.id)" class="flex gap-3">
                            <i class="text-xl bi bi-check-circle-fill text-valence-light-blue-400"></i>
                            <span class="mt-1 line-through" v-text="task.listTitlePrimary"></span>
                        </div>
                        <button v-else type="button" class="flex items-center gap-0 w-full" @click="setActiveTask(task.id)">
                            <i class="shrink-0 text-xl bi bi-circle mr-3"></i>
                            <span class="grow text-left" v-text="task.listTitlePrimary"></span>
                            <span class="shrink-0 h-5 w-5">
                                <ChevronRight />
                            </span>
                        </button>
                    </div>
                </template>
            </div>
        </template>
    </div>

    <dialog ref="dialogRef" if="hasPendingTasks" class="absolute bg-white rounded-xl px-16 py-8 max-w-screen-sm self-center pointer-events-auto">
        <button type="button" title="Dismiss" class="shrink-0 gray-300 absolute top-8 right-8" @click="dialogRef.close()">
            <span role="presentation" class="bi bi-x h-4 w-4"></span>
        </button>
        <template v-if="activeTask">
            <h2 class="text-2xl font-bold mb-8" v-text="activeTask.dialogTitle"></h2>
            <div class="flex flex-col my-2 justify-center">
                <img :src="activeTask.dialogGif" alt="" />
            </div>
            <div class="flex gap-4 mt-4 flex-col">
                <p v-text="activeTask.dialogCopy"></p>
                <div v-if="activeTask.markManually" class="flex flex-row gap-4 items-center">
                    <button
                        v-if="activeTask.markManually"
                        :disabled="loading"
                        type="button"
                        class="w-fit rounded-full px-12 py-3 leadine-none text-sm font-semibold text-white"
                        :class="{ 'bg-valence-pink-200': loading, 'hover:bg-valence-pink-200 bg-valence-pink-400': !loading }"
                        @click="markCompletedManually(activeTask.id)"
                    >
                        <template v-if="loading">
                            <div class="w-3 h-3"><LoadingSpinner /></div>
                        </template>
                        <template v-else>I&apos;ve done this</template>
                    </button>
                    <p v-if="error" class="font-medium text-red-700 text-sm" v-text="error"></p>
                </div>
            </div>
        </template>
    </dialog>
</template>

<script>
export const ONBOARDING_TASK = {
    ROLE: "action_onboarding_role",
    MIC: "action_onboarding_mic",
    FOLLOWUP: "action_onboarding_followup",
    GUIDANCE: "action_onboarding_guidance",
    BOOKMARK: "action_onboarding_bookmark",
};
</script>
<script setup>
import { getCookie } from "/js/utils.js";
import BookmarkGif from "~img/onboarding_gifs/bookmark.gif";
import FollowupGif from "~img/onboarding_gifs/followup.gif";
import MicGif from "~img/onboarding_gifs/mic.gif";
import { CHAT_EVENT } from "~vue/events.js";
import ChevronRight from "~vue/icons/ChevronRight.vue";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, onMounted, onUnmounted, ref, toRef, watch } from "vue";

const checklistData = [
    {
        id: ONBOARDING_TASK.ROLE,
        listTitlePrimary: "Tell me your role",
        dialogTitle: null,
        dialogCopy: null,
        dialogGif: null,
        markManually: false,
    },
    {
        id: ONBOARDING_TASK.MIC,
        listTitlePrimary: "Use your mic to speak",
        dialogTitle: "Use your mic to speak to me",
        dialogCopy:
            "Tap the “Reply Now” button below and start recording your first message to me. You don’t have to be concise here — please give me as much details as possible so I can better tailor my advice to your situation.",
        dialogGif: MicGif,
        markManually: false,
    },
    // Per request on SC-79676, we are just hiding this one for now rather than deleting it
    // {
    //     id: "action_onboarding_guidance",
    //     listTitlePrimary: "Get guidance on a leadership problem on your mind",
    //     dialogTitle: "Get guidance on a leadership problem on your mind",
    //     dialogCopy: "Start by telling me about a leadership challenge that’s on your mind. I’ll generate an agenda for us and walk you through some suggested action steps.",
    //     dialogGif: GuidanceGif,
    //     status: null,
    // },
    {
        id: ONBOARDING_TASK.FOLLOWUP,
        listTitlePrimary: "Schedule a follow-up",
        dialogTitle: "Schedule a follow-up with me",
        dialogCopy:
            "After we walk through our first coaching conversation together, I’ll suggest a time to check in on the action items we decide. Make sure to schedule some time so I can check in on your progress. I’ll send you a calendar invite so you don’t forget.",
        dialogGif: FollowupGif,
        markManually: false,
    },
    {
        id: ONBOARDING_TASK.BOOKMARK,
        listTitlePrimary: "Add me to your bookmarks",
        dialogTitle: "Add me to your bookmarks",
        dialogCopy: "Make it easy to find me again whenever you need coaching by tapping the “Star” in the address bar on your browser.",
        dialogGif: BookmarkGif,
        markManually: true,
    },
];

const props = defineProps({
    open: {
        type: Boolean,
        default: () => false,
    },
});

defineEmits(["toggle"]);

const globalProperties = inject("globalProperties");
const dialogRef = ref(null);
const activeTaskId = ref(null);
const error = ref(null);
const loading = ref(false);

onMounted(() => {
    if (dialogRef.value) {
        dialogRef.value.addEventListener("cancel", () => {
            activeTaskId.value = null;
        });
    }
    globalProperties.emitter.on(CHAT_EVENT.MARK_ONBOARDING_TASK, updateChecklist);
});

onUnmounted(() => {
    globalProperties.emitter.off(CHAT_EVENT.MARK_ONBOARDING_TASK, updateChecklist);
});

const pendingTasks = computed(() => {
    // Explicitly compare against false rather than ![key] to exclude undefined or null keys
    return checklistData.filter((task) => globalProperties.$userDetails.value.onboarding_checklist[task.id] === false);
});

const hasPendingTasks = computed(() => pendingTasks.value.length > 0);

const activeTask = computed(() => {
    if (activeTaskId.value === null) {
        return null;
    }

    return checklistData.find((task) => task.id === activeTaskId.value);
});

function isTaskComplete(taskId) {
    return !!globalProperties.$userDetails.value.onboarding_checklist[taskId];
}

function closeActiveTask() {
    activeTaskId.value = null;
}

function setActiveTask(taskId) {
    activeTaskId.value = taskId;
}

function markCompletedManually(taskId) {
    updateChecklist({
        taskId,
        onError: (e) => {
            error.value = e;
        },
        onSuccess: () => {
            closeActiveTask();
        },
    });
}

async function updateChecklist({ taskId, onSuccess, onError }) {
    loading.value = true;
    try {
        const update = {
            ...globalProperties.$userDetails.value.onboarding_checklist,
            [taskId]: true,
        };
        const response = await fetch("/accounts/onboarding_checklist", {
            method: "POST",
            body: JSON.stringify(update),
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });
        const body = await response.json();
        loading.value = false;
        if (!response.ok && onError) {
            onError("Failed to complete task");
            return;
        }

        if (!body.success && onError) {
            onError(body.errors);
            return;
        }

        globalProperties.$userDetails.value.onboarding_checklist = update;

        if (onSuccess) {
            onSuccess();
        }
    } catch (e) {
        loading.value = false;
        if (onError) {
            onError("Failed to complete task");
        }
        if (window.Sentry) {
            window.Sentry.captureException(e);
        }
    }
}

watch(toRef(props, "open"), (isOpen) => {
    if (isOpen) {
        logUserInteraction("checklist_viewed");
    }
});

watch(activeTaskId, (value) => {
    if (!dialogRef.value) {
        return;
    }

    if (value === null) {
        dialogRef.value.close();
    } else {
        dialogRef.value.showModal();
        logUserInteraction("checklist_item_clicked");
    }
});
</script>
