<template>
    <div>
        <div class="inline-block text-xs uppercase py-1 px-2 bg-valence-grey-100 rounded mb-2">Insights</div>
        <div>
            <h3 class="text-xl font-bold">{{ firstSentence }}</h3>
            <template v-if="isOpen">
                <ChatWidgetText class="text-sm" :content="restOfInsight" />
                <button v-if="!showSavedSuccess" class="block my-3 text-sm text-valence-grey-400" @click="saveInsight">
                    <i class="bi bi-bookmark"></i>
                    Save this as a topic to revisit
                </button>
                <p v-else class="block my-3 text-sm text-valence-grey-400">
                    <i class="bi bi-check-circle"></i>
                    Topic saved!
                </p>
            </template>
            <button class="text-xs text-valence-grey-400 cursor-pointer" @click="toggleOpen">
                {{ isOpen ? "Click to close" : "Click to view" }}
            </button>
        </div>
    </div>
</template>

<script setup>
/**
 * Serves double duty for displaying two similar widgets, the values insight widget and the internal thinking widget.
 *
 * Both allow saving the insight to a conversation topic for later.
 */
import ChatWidgetText from "~vue/ChatWidgetText.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, ref } from "vue";

const { emitter } = inject("globalProperties");

const props = defineProps({
    data: Object({}),
    role: String("user"),
    messageId: Number(0),
    lineIdx: Number(0),
    message: Object({}),
    action_type: String(""),
});

const isOpen = ref(false);
const showSavedSuccess = ref(false);

const insight = computed(() => props.data.action_params.final_response);

const firstSentence = computed(() => {
    return insight.value.split(". ")[0] + ".";
});

const restOfInsight = computed(() => {
    return insight.value.split(". ").slice(1).join(". ");
});

function toggleOpen() {
    isOpen.value = !isOpen.value;
    logUserInteraction(`${props.action_type}_minimize`, { minimized: props.data.action_state.minimized });
}

function saveInsight() {
    emitter.emit(props.action_type, {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        insight: insight.value,
        // TODO: Come back to this and set the coaching session ID properly if you have time
        coaching_session_id: window.location.pathname.split("/")[2],
    });
    logUserInteraction(`${props.action_type}_save_insight`);
    showSavedSuccess.value = true;
}
</script>
