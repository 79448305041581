export const ACTION = {
    CUSTOMIZABLE_TEXT: "customizable_text",
    FOLLOW_UP: "followup",
    FILE_UPLOAD: "file_upload",
    RATING: "rating",
    ONBOARDING_SUMMARY: "osummary",
    SUGGESTED_TOPICS: "suggested_topics",
    PERSONALIZED_ADVICE: "personalized_advice",
    PERSONALIZED_RATING: "personalized_rating",
    ROLE_PLAY_ADJUST: "role_play_adjust",
    INTERNAL_THINKING: "internal_thinking",
    ACTION_ITEMS: "action_items",
    VALUES_INSIGHT: "values_insight",
    TIP: "tip",
    LLM_EXPLANATION: "llm_explanation",
    SCENARIOS: "scenarios",
    PROFILE_QUESTION: "profile_question",
    ANSWERS: "answers",
    INFERRED_PROFILE_ANSWER: "inferred_profile_answer",
    FOCUS_AREA_PICKER: "focus_area_picker",
    ROLE_PLAY_MONITOR: "role_play_monitor",
};

export const SIDEBAR_WIDGETS = [
    ACTION.CUSTOMIZABLE_TEXT,
    ACTION.FOLLOW_UP,
    ACTION.ONBOARDING_SUMMARY,
    ACTION.SUGGESTED_TOPICS,
    ACTION.PERSONALIZED_RATING,
    ACTION.INTERNAL_THINKING,
    ACTION.ACTION_ITEMS,
    ACTION.VALUES_INSIGHT,
    ACTION.INFERRED_PROFILE_ANSWER,
];

export const CHAT_WIDGETS = [
    ACTION.ROLE_PLAY_ADJUST,
    ACTION.CUSTOMIZABLE_TEXT,
    ACTION.FOLLOW_UP,
    ACTION.ONBOARDING_SUMMARY,
    ACTION.SUGGESTED_TOPICS,
    ACTION.PERSONALIZED_RATING,
    ACTION.INTERNAL_THINKING,
    ACTION.ACTION_ITEMS,
    ACTION.VALUES_INSIGHT,
];

export const lineIsAction = (line) => {
    return line.type === "action";
};

export const lineIsWidget = (line, chatAction = false) => {
    const widgets = chatAction ? CHAT_WIDGETS : SIDEBAR_WIDGETS;
    return lineIsAction(line) && widgets.includes(line.action_name);
};

export const lineHasWidget = (line, widgetName, chatAction = false) => {
    return lineIsWidget(line, chatAction) && line.action_name === widgetName;
};

export const pluckAction = (chatMessages, actionName, chatAction = false) => {
    for (let message of chatMessages) {
        for (let line of message.lines) {
            if (lineHasWidget(line, actionName, chatAction)) {
                return {
                    line,
                    lineIdx: message.lines.indexOf(line),
                    message,
                };
            }
        }
    }

    return null;
};

export const actionExists = (chatMessages, actionName) => {
    return chatMessages.some((message) => message.lines.some((l) => lineHasWidget(l, actionName)));
};

export const messageHasActions = (message) => message.lines.some((l) => lineIsAction(l));

export const lineHasScenarioActionParams = (line) => lineIsAction(line) && line?.action_params?.scenarios?.length > 0;

const isRolePlayMonitor = (line) => line.action_name === ACTION.ROLE_PLAY_MONITOR;

export const isRolePlayMessage = (message) => {
    return message.lines.some((l) => isRolePlayMonitor(l));
};

export const getRolePlayMonitorParams = (message) => {
    const action = message.lines.find((l) => isRolePlayMonitor(l));
    return action ? action.action_params : null;
};

export const hasRolePlayMonitor = (messages) => {
    return messages.some((m) => isRolePlayMessage(m));
};
