/*
 * Taken from https://github.com/ramkumarshankar/vue-simple-websocket/blob/master/src/WebSocketClient.js
 * with only 1 modification: removed the console.log call in the constructor.
 *
 * This is taken from our existing WebSocket package, "vue-simple-socket".
 * The package works by configuring the socket client as a Vue plugin, which persists
 * throughout the lifetime of the session. However, now that we're using Inertia, we
 * can load multiple chats within the session. As such, the socket client needs to be
 * torn and recreated each time.
 *
 * For now, lets use the same code but have the Vue component manage it as a piece of
 * component state. This way we avoid updating the entire WebSocket implementation as part of
 * the migration to Inertia.
 */
export default class WebSocketClient {
    constructor(url, options) {
        this.instance = null;
        this.url = url;
        this.options = options || this.defaultOptions();
        if (this.options) {
            this.reconnectEnabled = options.reconnectEnabled || false;
            if (this.reconnectEnabled) {
                this.reconnectInterval = options.reconnectInterval;
            }
        }
        // These methods should be defined by components
        this.onOpen = null;
        this.onMessage = null;
        this.onClose = null;
        this.onError = null;
    }

    defaultOptions() {
        return {
            reconnectEnabled: false,
            reconnectInterval: 0,
        };
    }

    connect() {
        this.instance = new WebSocket(this.url);

        // Socket event listeners
        // Each event handler also calls the corresponding class method, which can be defined by the component
        this.instance.onopen = () => {
            if (typeof this.onOpen === "function") {
                this.onOpen();
            }
        };
        this.instance.onmessage = (msg) => {
            if (typeof this.onMessage === "function") {
                this.onMessage(msg);
            }
        };
        this.instance.onclose = (evt) => {
            if (typeof this.onClose === "function") {
                this.onClose(evt);
            }
            if (!evt.wasClean && this.reconnectEnabled) {
                this.reconnect();
            }
        };
        this.instance.onerror = (evt) => {
            if (typeof this.onError === "function") {
                this.onError(evt);
            }
        };
    }

    reconnect() {
        delete this.instance;
        setTimeout(() => {
            this.connect();
        }, this.reconnectInterval);
    }

    send(message) {
        this.instance.send(message);
    }

    sendObj(data) {
        this.instance.send(JSON.stringify(data));
    }

    removeListeners() {
        this.onOpen = null;
        this.onMessage = null;
        this.onClose = null;
        this.onError = null;
    }
}
