<template>
    <div :class="isVisible ? '' : 'invisible'" class="flex gap-1">
        <button
            v-if="showThoughtProcess"
            type="button"
            class="p-1 opacity-50 hover:opacity-100 tooltip tooltip-bottom active:bg-white transition-colors transition-opacity rounded"
            data-tip="Explain my thought process"
            @click="$emit('thoughtProcessClicked', message)"
        >
            <HelpCircle />
        </button>
        <button
            type="button"
            class="p-1 opacity-50 hover:opacity-100 tooltip tooltip-bottom active:bg-white transition-colors transition-opacity rounded"
            :data-tip="isCopied ? 'Copied!' : 'Copy to clipboard'"
            @click="handleCopyLine"
            @mouseout="handleCopyMouseOut"
        >
            <Copy />
        </button>
    </div>
</template>

<script setup>
import DOMPurify from "dompurify";
import { marked } from "marked";
import { computed, defineEmits, defineProps, ref } from "vue";

import Copy from "./icons/Copy.vue";
import HelpCircle from "./icons/HelpCircle.vue";

const isCopied = ref(false);

const { message, lineIdx } = defineProps({
    message: Object({}),
    lineIdx: Number(),
    isVisible: Boolean(true),
    showThoughtProcess: Boolean(true),
});

defineEmits(["thoughtProcessClicked"]);

const line = computed(() => {
    if (!message || !message.lines) {
        return null;
    }
    const line = message.lines[lineIdx];
    if (!line) {
        return null;
    }
    return line;
});

async function handleCopyLine() {
    if (!line.value) {
        return;
    }

    let plain = line.value.content;
    let html = line.value.content;

    if (line.value.type === "md") {
        html = DOMPurify.sanitize(marked.parse(line.value.content, { headerIds: false, mangle: false }));
    }

    const data = new ClipboardItem({
        "text/html": new Blob([html], { type: "text/html" }),
        "text/plain": new Blob([plain], { type: "text/plain" }),
    });
    await navigator.clipboard.write([data]);
    isCopied.value = true;
}

function handleCopyMouseOut() {
    if (isCopied.value === true) {
        setTimeout(() => {
            isCopied.value = false;
        }, 300);
    }
}
</script>
