<template>
    <div
        v-if="!dismissed"
        class="relative mx-3 my-6 text-valence-violet-800 bg-valence-violet-50 border-valence-violet-800 border rounded-lg p-4 leading-none flex flex-column gap-3 items-start"
    >
        <template v-if="line">
            <!-- icon -->
            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4596_8)">
                    <path d="M8 12V14.6667" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.8267 10.8267L12.7133 12.7133" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3.28668 12.7133L5.17335 10.8267" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 8H14.6667" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.33334 8H4.00001" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.8267 5.17333L12.7133 3.28667" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3.28668 3.28667L5.17335 5.17333" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8 1.33334V4" stroke="#5B08C9" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_4596_8">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </template>
        <div class="w-full font-medium">
            <template v-if="line">
                <div class="relative flex flex-col md:flex-row md:items-center md:justify-between">
                    <p :class="['font-medium', { 'text-sm': !(line && showInferredAnswer) }, { 'text-lg': line && showInferredAnswer }]" class="flex-grow">
                        {{ notificationHeader }}
                    </p>
                    <button
                        v-if="!showInferredAnswer"
                        type="button"
                        class="text-sm underline hover:text-valence-violet-800/75 md:ml-4 md:flex-shrink-0 pr-4"
                        @click="() => (showInferredAnswer = !showInferredAnswer)"
                    >
                        Review
                    </button>
                </div>
                <div class="relative mx-3 text-valence-violet-800 bg-valence-violet-50 border-valence-violet-800 rounded-lg leading-none flex flex-col gap-3">
                    <div v-if="showInferredAnswer" class="mt-4">
                        <h3 class="text-gray-800 capitalize text-md">{{ formattedKey }}:</h3>
                        <p v-if="!editInferredAnswer" class="mb-4 text-gray-800 leading-normal">{{ inferredAnswer }}</p>
                        <div v-else class="mt-2 rounded-xl py-2 pr-10 pl-4 bg-white flex flex-row gap-4 items-center shadow-sm">
                            <textarea
                                v-model="inferredAnswer"
                                class="grow focus:outline-none leading-normal text-base text-gray-700"
                                :placeholder="inferredAnswer"
                                autofocus=""
                                autocomplete="off"
                            ></textarea>
                        </div>
                        <div class="flex justify-between mt-4 font-medium">
                            <button
                                v-if="!editInferredAnswer"
                                type="button"
                                class="px-8 py-2 border border-valence-violet-800 rounded-lg text-valence-violet-800 hover:bg-valence-violet-100 notification-btn"
                                @click="() => (editInferredAnswer = !editInferredAnswer)"
                            >
                                Edit
                            </button>
                            <button
                                v-else
                                type="button"
                                class="px-8 py-2 border border-valence-violet-800 rounded-lg text-valence-violet-800 hover:bg-valence-violet-100 notification-btn"
                                @click="handleInferredAnswerEvent(EVENT_TYPES.EDIT)"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                class="px-4 py-2 text-valence-violet-800 underline hover:text-valence-violet-600"
                                @click="handleInferredAnswerEvent(EVENT_TYPES.DELETE)"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <i class="bi bi-x absolute right-2 top-2 cursor-pointer" @click="handleInferredAnswerEvent(EVENT_TYPES.DISMISS)"></i>
    </div>
</template>

<script setup>
import { CHAT_EVENT } from "~vue/events.js";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, defineProps, inject, onMounted, ref } from "vue";

const EVENT_TYPES = {
    DELETE: "delete",
    EDIT: "edit",
    DISMISS: "dismiss",
};

const { emitter } = inject("globalProperties");

const props = defineProps({
    messageId: Number,
    lineIdx: Number,
    line: Object,
    custom: Boolean,
});

// Util
const formatKey = (key) => {
    if (!key) {
        return "";
    }
    return key.replace(/_/g, " ");
};

// Refs
const showInferredAnswer = ref(false);
const editInferredAnswer = ref(false);
const line = ref(props.line);
const dismissed = ref(line.value.action_state?.dismissed);
const inferredAnswer = ref(line.value.action_params?.inferred_answer);

// Computed
const formattedKey = computed(() => {
    return formatKey(line.value.action_params?.key);
});

const notificationHeader = ref(`I just learned about your ${formattedKey.value}, I'll remember this from now on.`);

// Methods
const dismiss = () => {
    dismissed.value = true;
};

const handleInferredAnswerEvent = (eventType) => {
    let actionState = { ...line.value.action_state };
    let actionParams = { ...line.value.action_params, inferred_answer: inferredAnswer.value };
    let subaction = eventType;

    if (eventType === EVENT_TYPES.EDIT) {
        // for visual purposes
        editInferredAnswer.value = false;
        notificationHeader.value = "Updated! This has been saved to your profile.";
        logUserInteraction("inferred_profile_answer_edit");
    } else if (eventType === EVENT_TYPES.DELETE) {
        logUserInteraction("inferred_profile_answer_delete");
    }

    emitter.emit("inferred_profile_answer", {
        action_state: actionState,
        action_params: actionParams,
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        subaction: subaction,
    });

    setTimeout(() => {
        dismiss();
        emitter.emit("profile_notification_changed", {});
    }, 750);
};

onMounted(() => {
    emitter.emit("profile_notification_changed", {});
    emitter.emit(CHAT_EVENT.INFERENCE_MADE, {});
});
</script>

<style scoped>
.notification-btn {
    border-radius: 1.5rem;
}
</style>
