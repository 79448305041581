<template>
    <p v-html="renderableContent"></p>
</template>

<script setup>
import { marked } from "marked"; 
import DOMPurify from "dompurify";
import {computed, defineProps } from 'vue';

const props = defineProps({
    content: String
})

const renderableContent = computed(() => {
    if (!props.content){
        return ""
    }

    return DOMPurify.sanitize(
        marked.parse(props.content)
            .replaceAll("\n\n", "\n")
            .replaceAll("```", "")
    );
});
</script>
<style>
strong {
    font-weight: bold;
}
</style>