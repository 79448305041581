<template>
    <div v-if="showDisclaimer" class="relative mx-auto leading-none p-2 flex flex-row justify-center items-center disclaimer">
        <div class="text-center">
            <p v-if="noModal" class="text-xs text-valence-grey-400 italic" v-html="disclaimerMessage"></p>
            <p v-else class="text-xs text-valence-grey-400 italic">
                Remember to adhere to company <span role="button" class="underline cursor-pointer" @click="openDialog()">guidelines!</span>
            </p>
        </div>
    </div>
    <dialog ref="dialogRef" class="focus-visible:outline-0 bg-white md:p-16 p-4 rounded-xl text-valence-grey-800 relative disclaimer-dialog">
        <button type="button" class="absolute top-2 right-2 p-3" @click="closeDialog()">
            <i class="bi bi-x text-xl text-valence-grey-600 hover:text-valence-grey-600/80"></i>
        </button>
        <div class="text-xl font-bold text-center mb-4">Company Disclaimer</div>
        <div class="mt-2 text-left">
            <p class="text-valence-grey-400" v-html="disclaimerMessage"></p>
        </div>
    </dialog>
</template>

<script setup>
import { toMarkdown } from "/js/utils";
import { computed, ref } from "vue";

const props = defineProps({
    message: {
        type: String,
        default: () => "",
    },
});

const msg = props.message?.replace("/no-modal/", "").trim();
const noModal = props.message?.includes("/no-modal/");

// Ref
const dialogRef = ref(null);

// Methods
const openDialog = () => {
    dialogRef.value.showModal();
};

const closeDialog = () => {
    dialogRef.value.close();
};

// Computed
const showDisclaimer = computed(() => {
    return !!props.message;
});

const disclaimerMessage = computed(() => {
    return toMarkdown(msg);
});
</script>

<style scoped>
.disclaimer {
    z-index: 10;
}

.disclaimer-dialog {
    width: 550px;
    margin: auto;
    padding: 20px;
    text-align: center;
}
</style>
