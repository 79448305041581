<template>
    <div id="chat-container__stream" :class="{ 'hidden md:flex': showWidgetSidebar }" class="grow overflow-y-auto md:flex">
        <div class="w-full chat_component rounded-xl round md:max-w-screen-md md:mx-auto">
            <div ref="message_container" class="relative messages px-4 sm:mt-8 md:px-8">
                <div ref="chatGroup" class="chat-group">
                    <Transition name="fade">
                        <div v-if="!showStartLoadingMessage" class="fs-mask chat-bubbles" data-cy="chat-stream">
                            <template v-for="(message, idx) in messages" :key="idx">
                                <template v-for="(line, lineIdx) in message.lines" :key="lineIdx">
                                    <div v-if="line.action_name == actions.FILE_UPLOAD && line.action_params.length > 0" class="flex my-1 justify-end">
                                        <div class="overflow-auto max-w-[425px] ml-auto flex gap-2">
                                            <FileAttachedTag v-for="file in line.action_params" :key="file.name" :file-name="file.name" :file-description="file.description" />
                                        </div>
                                    </div>
                                    <div
                                        v-if="line.type !== 'action' && line.type !== 'tool' && !line.hidden"
                                        class="chat"
                                        :class="message.role == 'user' ? 'chat-end' : 'chat-start'"
                                        @mouseover="handleLineMouseOver(message.id, lineIdx)"
                                        @mouseout="handleLineMouseOut(message.id, lineIdx)"
                                    >
                                        <div
                                            v-if="line.type != 'action'"
                                            :ref="refForMessage(message.chat_message_id)"
                                            class="chat-message max-w-full px-4 relative ease-in-out duration-500 transition-colors rounded-xl"
                                            :class="{
                                                coach: message.role === 'assistant',
                                                'user text-left rounded-xl': message.role === 'user',
                                                'bg-white': message.role === 'user' && message.chat_message_id !== focusedMessage,
                                                'bg-valence-purple/10': message.chat_message_id === focusedMessage,
                                            }"
                                        >
                                            <div data-id="chat-anchor" class="chat-anchor" style="position: absolute; top: -90px; left: 0"></div>
                                            <article class="prose w-full text-sm md:text-lg lg:text-lg text-left">
                                                <ChatMessageText
                                                    v-if="line.type === 'md'"
                                                    :message="line.content"
                                                    :animate="shouldAnimateMessageText(message, idx)"
                                                    @finished-rendering="handleLineRendered(message.id, lineIdx)"
                                                />
                                                <div v-if="line.type == 'html'" data-cy="chat-stream_message" v-html="line.content" />
                                                <ChatMessageReactionBar
                                                    v-if="shouldIncludeReactionBar(message, lineIdx)"
                                                    :message="message"
                                                    :line-idx="lineIdx"
                                                    :is-visible="shouldRevealReactionBar(message.id, lineIdx)"
                                                    :show-thought-process="idx === messages.length - 1"
                                                    class="mt-[-18px]"
                                                    @thought-process-clicked="handleThoughtProcessClicked"
                                                />
                                                <template v-if="line.type == 'action_set'">
                                                    <div v-for="(action, action_idx) in line.content.actions" :key="action_idx" class="flex">
                                                        <div data-cy="chat-stream_message" class="btn">{{ action.content }}</div>
                                                    </div>
                                                </template>
                                            </article>
                                            <Transition name="fade">
                                                <template v-if="showRolePlayInsightButton(message)">
                                                    <button
                                                        type="button"
                                                        class="bg-valence-pink-50/75 hover:bg-valence-pink-50 text-xs font-medium mb-2 px-2 py-1 rounded-xl text-valence-grey-600"
                                                        @click="focusRolePlayFeedback(message.chat_message_id)"
                                                    >
                                                        View insight
                                                    </button>
                                                </template>
                                            </Transition>
                                        </div>
                                    </div>
                                    <div v-if="line.type === 'tool' && line.tool_call.tool_name == 'coachable_bullet_points'">
                                        <CoachableBulletPoints :tool_call="line.tool_call" :message-id="message.chat_message_id" :line-idx="lineIdx" />
                                    </div>
                                    <div v-if="line.type === 'tool' && line.tool_call.tool_name == 'coachable_bullet_points_multiselect'">
                                        <CoachableBulletPointsMultiselect :tool_call="line.tool_call" :message-id="message.chat_message_id" :line-idx="lineIdx" />
                                    </div>
                                    <ChatActionAnswers v-if="shouldShowAnswers(line)" :data="line" :message-id="message.chat_message_id" :line-idx="lineIdx" :role="message.role" />
                                    <ChatActionScenarios
                                        v-if="shouldShowSuggestedScenarios(line)"
                                        :data="line"
                                        :message-id="message.chat_message_id"
                                        :line-idx="lineIdx"
                                        :role="message.role"
                                    />
                                    <ChatActionFocusAreas v-if="shouldShowFocusAreas(line)" :data="line" :theme="white" />
                                    <ChatActionScheduledFollowUp v-if="shouldShowScheduledFollowUp(line)" :line="line" />
                                    <Transition name="fade">
                                        <ChatNotificationMessage v-if="shouldShowNotification(line)" :line="line" :line-idx="lineIdx" :message-id="message.chat_message_id" />
                                    </Transition>
                                    <Transition name="fade">
                                        <ChatInferredAnswerNotification
                                            v-if="shouldShowInferenceNotification(line)"
                                            :line="line"
                                            :line-idx="lineIdx"
                                            :message-id="message.chat_message_id"
                                        />
                                    </Transition>
                                </template>
                            </template>
                        </div>
                    </Transition>
                    <div ref="bottom"></div>
                    <Transition type="transition" name="fade" mode="out-in">
                        <ChatLoadingMessage v-if="showStartLoadingMessage" :loading-message-stack="loadingMessageStack" @complete="handleLoadingMessageComplete" />
                        <CoachingModeMarble v-else-if="waiting || isSystemMessageAnimating" :is-loading="true" />
                        <div v-else-if="!showFailedResponse && showSlowResponse" class="text-left flex">
                            <ChatWaitingMessage>One moment while I gather my thoughts.</ChatWaitingMessage>
                        </div>
                        <!-- this is a placeholder element to prevent layout shift when marble no longer shows -->
                        <div v-else class="min-h-16"></div>
                    </Transition>

                    <div
                        v-if="showFailedResponse"
                        class="w-full flex justify-items-center items-center align-middle z-50 fixed left-0 top-0 bottom-0 h-full bg-white bg-opacity-50"
                    >
                        <div
                            class="relative failed_response rounded-md border-4 border-valence-pink bg-base-100 bg-opacity-100 shadow-md w-80 md:w-auto md:max-w-screen-md mx-auto p-4 md:p-12"
                        >
                            <button type="button" class="absolute top-2 right-2 text-neutral-300 text-3xl cursor-pointer" @click="handleDismissedFailure">
                                <i class="bi bi-x" />
                            </button>
                            <div class="items-center md:text-xl w-full">
                                <div class="h-16 w-16 md:h-32 md:w-32 my-4 md:mb-8 mx-auto text-valence-pink">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                        <path
                                            d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"
                                        />
                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                    </svg>
                                </div>
                                <div class="md:ml-12">
                                    <p class="mb-4">Oh dear! Something seems to have gone wrong in sending my reply to you.</p>
                                    <p class="mb-4">
                                        I&apos;m so sorry, sometimes this happens when I&apos;m too busy. Would you mind trying to refresh our chat? The conversation will pick up
                                        where we left off.
                                    </p>
                                    <p class="mb-4">Click the button below when you&apos;re ready to continue.</p>
                                </div>
                            </div>
                            <div class="w-full flex items-center mt-4">
                                <button type="button" class="btn btn-primary mx-auto" @click="handleRefresh">Refresh the Chat</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { logUserInteraction } from "~vue/utils/logUtils.js";

import ChatActionAnswers from "./ChatActionAnswers.vue";
import ChatActionFocusAreas from "./ChatActionFocusAreas.vue";
import { ACTION, getRolePlayMonitorParams, isRolePlayMessage, lineIsAction } from "./chatActions.js";
import ChatActionScenarios from "./ChatActionScenarios.vue";
import ChatActionScheduledFollowUp from "./ChatActionScheduledFollowUp.vue";
import ChatInferredAnswerNotification from "./ChatInferredAnswerNotification.vue";
import ChatLoadingMessage from "./ChatLoadingMessage.vue";
import ChatMessageReactionBar from "./ChatMessageReactionBar.vue";
import ChatMessageText from "./ChatMessageText.vue";
import ChatNotificationMessage, { lineHasNotification } from "./ChatNotificationMessage.vue";
import ChatWaitingMessage from "./ChatWaitingMessage.vue";
import FileAttachedTag from "./components/FileAttachedTag.vue";
import CoachingModeMarble from "./components/navigation/CoachingModeMarble.vue";
import { CHAT_EVENT } from "./events.js";
import { useChatStore } from "./stores/chatStore.js";
import CoachableBulletPoints from "./StructuredOutputs/CoachableBulletPoints.vue";
import CoachableBulletPointsMultiselect from "./StructuredOutputs/CoachableBulletPointsMultiselect.vue";

export default {
    name: "ChatMessageList",
    components: {
        ChatActionAnswers,
        ChatActionScenarios,
        ChatActionScheduledFollowUp,
        ChatLoadingMessage,
        ChatMessageReactionBar,
        ChatNotificationMessage,
        ChatMessageText,
        ChatWaitingMessage,
        CoachableBulletPoints,
        CoachableBulletPointsMultiselect,
        ChatInferredAnswerNotification,
        FileAttachedTag,
        ChatActionFocusAreas,
        CoachingModeMarble,
    },
    props: {
        loadingMessageStack: Array,
        dismissSuggestedScenarios: Boolean,
        messages: Array,
        numberOfMessagesAtInit: Number,
        showFailedResponse: Boolean,
        showSlowResponse: Boolean,
        showStartLoadingMessage: Boolean,
        showWidgetSidebar: Boolean,
        waiting: Boolean,
        followUpNotificationData: Object,
    },
    emits: ["dismiss-failure", "loading-complete"],
    data() {
        return {
            focusedMessage: null,
            renderedLines: new Set(),
            hoverLine: "",
        };
    },
    computed: {
        actions() {
            return ACTION;
        },
        isSystemMessageAnimating() {
            return useChatStore.systemMessageAnimating;
        },
    },
    watch: {
        followUpNotificationData() {
            logUserInteraction("follow_up_notification_presented");
        },
    },
    mounted() {
        this.emitter.on(CHAT_EVENT.FOCUS_CHAT_MESSAGE, this.handleFocusChatMessage);
    },
    unmounted() {
        this.emitter.off(CHAT_EVENT.FOCUS_CHAT_MESSAGE, this.handleFocusChatMessage);
    },
    methods: {
        isRolePlayMessage,
        getRolePlayMonitorParams,
        refForMessage(chatMessageId) {
            return `m-${chatMessageId}`;
        },
        handleFocusChatMessage({ chatMessageId }) {
            const messageRef = this.$refs[this.refForMessage(chatMessageId)];
            if (messageRef && messageRef[0] instanceof HTMLElement) {
                messageRef[0].scrollIntoView({ block: "center", behavior: "smooth" });
                this.focusedMessage = chatMessageId;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.focusedMessage = null;
                    }, 1000);
                });
            }
        },
        showRolePlayInsightButton(message) {
            if (!isRolePlayMessage(message)) {
                return false;
            }

            const params = getRolePlayMonitorParams(message);
            return params && !!params.feedback;
        },
        focusRolePlayFeedback(chatMessageId) {
            // Open the sidebar if it's closed, and focus the feedback
            // on the next tick.
            this.emitter.emit(CHAT_EVENT.FOCUS_WIDGET, { actionName: ACTION.ROLE_PLAY_MONITOR });
            this.$nextTick(() => {
                this.emitter.emit(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, chatMessageId);
            });
        },
        getChatGroupElement() {
            return this.$refs.chatGroup;
        },
        getChatBottom() {
            return this.$refs.bottom;
        },
        handleLoadingMessageComplete() {
            this.$emit("loading-complete");
        },
        handleDismissedFailure() {
            this.$emit("dismiss-failure");
        },
        handleRefresh() {
            document.location.reload();
        },
        handleLineRendered(messageId, lineIdx) {
            this.renderedLines.add(`${messageId}-${lineIdx}`);
        },
        handleLineMouseOver(messageId, lineIdx) {
            this.hoverLine = `${messageId}-${lineIdx}`;
        },
        handleLineMouseOut(messageId, lineIdx) {
            if (this.hoverLine === `${messageId}-${lineIdx}`) {
                this.hoverLine = "";
            }
        },
        handleThoughtProcessClicked(message) {
            this.emitter.emit(CHAT_EVENT.OPEN_THOUGHT_PROCESS_DIALOG, message.id);
        },
        shouldAnimateMessageText(message, messageIndex) {
            /* Only animate a message from the LLM that is being
             * streamed in real-time.
             */
            if (message.role !== "assistant") {
                return false;
            }

            if (messageIndex < this.numberOfMessagesAtInit) {
                return false;
            }

            return messageIndex >= this.messages.length - 1;
        },
        shouldShowAnswers(line) {
            return lineIsAction(line) && line.action_name === ACTION.ANSWERS;
        },
        shouldShowFocusAreas(line) {
            return lineIsAction(line) && line.action_name === ACTION.FOCUS_AREA_PICKER;
        },
        shouldShowNotification(line) {
            return lineHasNotification(line);
        },
        shouldShowInferenceNotification(line) {
            return line && line.action_name === ACTION.INFERRED_PROFILE_ANSWER;
        },
        shouldShowSuggestedScenarios(line) {
            return lineIsAction(line) && line.action_name === ACTION.SCENARIOS && !this.dismissSuggestedScenarios && this.messages.length <= 2;
        },
        shouldShowScheduledFollowUp(line) {
            return lineIsAction(line) && line.action_name === ACTION.FOLLOW_UP && line.action_params.event_at_confirmed && line.action_state.submitted;
        },
        shouldIncludeReactionBar(message, lineIdx) {
            if (!message.lines) {
                return;
            }
            const line = message.lines[lineIdx];
            if (!line) {
                return;
            }
            const isHidden = !!line.hidden;
            const isCoach = message.role === "assistant";
            const isTextLine = line.type === "md" || line.type === "html";
            const isFinishedRendering = this.renderedLines.has(`${message.id}-${lineIdx}`);
            return !isHidden && isCoach && isTextLine && isFinishedRendering;
        },
        shouldRevealReactionBar(messageId, lineIdx) {
            return this.hoverLine === `${messageId}-${lineIdx}`;
        },
    },
};
</script>

<style type="postcss">
.chat_component .chat {
    grid-template-columns: none;
    @apply w-full;

    article.prose p {
        word-break: break-word;
    }

    article.prose.font-serif {
        line-height: 2rem;
    }
}

.chat_component ol,
.chat_component ul {
    margin-left: 1.5em;
}
.chat_component ul {
    list-style: disc;
}
.chat_component ol {
    list-style-type: decimal;
}
.chat_component .messages {
    height: 100%;
    max-height: 80vh;
}
.chat_component .btn-circle {
    @apply rounded-full px-2;
}
.chat_component .btn-circle.btn-error {
    color: #f00;
    border: 2px solid #f00;
}
.chat_component .failed_response .btn-circle i {
    /* @apply text-error; */
    font-size: 2em;
}
.chat_component .bg-pink {
    /*  border-color: #ff2891;*/
    background-color: #ff2891;
    color: #fff;
}
.chat_component .text-valence-pink {
    color: #ff2891;
}
.chat_component .text-valence-purple {
    color: #bc63e8;
}
.chat_component .mic-indicator {
    left: 1rem;
    bottom: 14px;
}
.chat_component .btn:active .mic-indicator,
.chat_component .btn:active:hover .mic-indicator {
    left: 1rem;
    bottom: 14px;
}

/* This shouldn't be necessary, but things got weird for me at one point so I just did a quick override.  Please fix this properly! -S */
.chat.chat-start {
    place-items: start;
}
.chat.chat-start .chat-message {
    grid-column-start: 1;
    text-align: left;
}
.chat.chat-end {
    place-items: end;
}
.chat-message h2 {
    @apply font-sans text-3xl;
}
.chat.chat-end .chat-message {
    grid-column-start: 2;
    text-align: right;
}

@keyframes scaleIn {
    0% {
        scaley: 0.8;
    }
    100% {
        scaley: 1;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 200 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
