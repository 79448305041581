<template>
    <dialog id="devtools-dialog" ref="dialogElement" class="focus-visible:outline-0 bg-white p-4 rounded-xl text-valence-grey-800">
        <div class="flex flex-col gap-4 text-center h-[75vh] w-[50vw]">
            <nav class="shrink-0">
                <ul class="flex items-center gap-1">
                    <template v-for="p in paneList" :key="p">
                        <li>
                            <button
                                class="px-2 p-1 font-medium rounded-xl text-xs"
                                :class="isActive(p) ? 'bg-valence-grey-200' : 'hover:bg-valence-grey-50'"
                                type="button"
                                :aria-pressed="isActive(p)"
                                @click="setPane(p)"
                                v-text="getPaneTitle(p)"
                            ></button>
                        </li>
                    </template>
                </ul>
            </nav>
            <div class="overflow-hidden grow flex flex-col">
                <div class="shrink-0 text-left font-medium" v-text="getPaneTitle(activePane)"></div>
                <div class="grow overflow-y-auto">
                    <div v-show="isActive(pane.ADD_ACTION)">
                        <form class="flex flex-col gap-4 text-sm text-left" @submit="handleAddAction">
                            <div class="grid items-start grid-cols-12 gap-2">
                                <div class="flex flex-col col-span-4">
                                    <label class="font-medium" for="action-name">Action</label>
                                    <select id="action-name" v-model="newAction.action" class="py-2 px-1" name="actionName">
                                        <template v-for="a in actionList" :key="a">
                                            <option :value="a" v-text="a"></option>
                                        </template>
                                    </select>
                                </div>
                                <div class="flex flex-col col-span-8">
                                    <label class="font-medium" for="message-id">Message</label>
                                    <select id="message-id" v-model="newAction.messageId" class="py-2 px-1" name="messageId">
                                        <template v-for="message in messages" :key="message.chat_message_id">
                                            <option :value="message.chat_message_id" v-text="getMessagePreview(message)"></option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div class="flex flex-col">
                                    <div class="font-medium">Action params (JSON)</div>
                                    <div id="action-params" ref="actionEditor" class="text-xs bg-valence-grey-50"></div>
                                </div>
                            </div>
                            <div class="flex items-center gap-2">
                                <button
                                    :class="newAction.loading ? 'bg-valence-pink-800/50' : 'bg-valence-pink-800 hover:bg-valence-pink-800/75'"
                                    :disabled="newAction.loading"
                                    type="submit"
                                    class="block text-sm font-medium text-white px-3 py-1 rounded-lg"
                                >
                                    Add action
                                </button>
                                <div v-if="newAction.loading" class="text-valence-grey-400 h-4 w-4">
                                    <LoadingSpinner />
                                </div>
                            </div>
                            <div v-if="newAction.error">
                                <p class="text-xs text-red-500 font-medium">Failed to created action: {{ newAction.error.message }}</p>
                            </div>
                        </form>
                    </div>
                    <template v-if="isActive(pane.ACTIONS)">
                        <div class="text-left flex flex-col">
                            <template v-for="message in messages" :key="message.chat_message_id">
                                <template v-if="messageHasActions(message)">
                                    <div class="border-b last:border-b-0 border-b-grey-200 py-4 px-2 flex flex-col gap-2">
                                        <div class="text-sm">
                                            <div class="font-medium">Message</div>
                                            <p v-text="getMessagePreview(message, 150)"></p>
                                        </div>
                                        <div class="text-sm">
                                            <div class="font-medium">Actions</div>
                                            <div class="flex flex-col">
                                                <template v-for="(line, idx) in message.lines" :key="idx">
                                                    <details v-if="lineIsAction(line)">
                                                        <summary class="cursor-pointer">
                                                            <pre class="inline">{{ line.action_name }}</pre>
                                                        </summary>
                                                        <div class="p-2 bg-valence-grey-50">
                                                            <dl class="text-xs">
                                                                <dt class="mb-1 font-medium">Dismissed</dt>
                                                                <dd>
                                                                    <pre>{{ line.action_state.dismissed }}</pre>
                                                                </dd>
                                                                <dt class="mb-1 font-medium">Submitted</dt>
                                                                <dd>
                                                                    <pre>{{ line.action_state.submitted }}</pre>
                                                                </dd>
                                                                <dt class="mb-1 font-medium">Params</dt>
                                                                <dd>
                                                                    <code>{{ line.action_params }}</code>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </details>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </template>
                    <template v-if="isActive(pane.TRIGGER_TOOL)">
                        <form class="flex flex-col gap-4 text-sm text-left" @submit="handleTriggerTool">
                            <div class="grid items-start grid-cols-12 gap-2">
                                <div class="flex flex-col col-span-4">
                                    <label class="font-medium" for="tool-name">Tool</label>
                                    <select id="tool-name" v-model="toolToRun.tool" class="py-2 px-1" name="toolName">
                                        <template v-for="t in toolList" :key="t">
                                            <option :value="t" v-text="t"></option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div class="flex items-center gap-2">
                                <button
                                    :class="toolToRun.loading ? 'bg-valence-pink-800/50' : 'bg-valence-pink-800 hover:bg-valence-pink-800/75'"
                                    :disabled="toolToRun.loading"
                                    type="submit"
                                    class="block text-sm font-medium text-white px-3 py-1 rounded-lg"
                                >
                                    Run
                                </button>
                                <div v-if="toolToRun.loading" class="text-valence-grey-400 h-4 w-4">
                                    <LoadingSpinner />
                                </div>
                            </div>
                            <div v-if="toolToRun.error">
                                <p class="text-xs text-red-500 font-medium">Failed to run tool: {{ toolToRun.error.message }}</p>
                            </div>
                        </form>
                    </template>
                    <template v-if="isActive(pane.CHAT_SCRIPT)">
                        <form class="flex flex-col gap-4 text-sm text-left" @submit.prevent="onSubmit">
                            <div class="grid gap-2">
                                <div class="flex">
                                    <button class="w-1/8 pr-2" @click="toggleChatScriptInstructionsVisibility">Help <i class="bi bi-question-circle-fill text-blue-700" /></button>
                                    <ul v-show="chatScriptInstructionsVisible" class="w-7/8">
                                        <li>On dialog load, hit `Enter` to run the script.</li>
                                        <li>Changes are saved automatically to browser local storage. Save backups locally for long term reuse.</li>
                                        <li>To skip a response, e.g. waiting for system message insertion, create a message with no content.</li>
                                    </ul>
                                </div>
                                <div class="flex w-full">
                                    <label for="script_name" class="w-1/5 font-semibold">Script Name</label>
                                    <input id="script_name" v-model="chatScript.name" type="text" class="border" />
                                </div>
                                <div class="flex w-full">
                                    <label for="script_description_0" class="w-1/5 font-semibold">User Messages</label>
                                    <div class="w-4/5">
                                        <div v-for="(msg, idx) in chatScript.messages" :key="idx" class="flex w-full gap-1">
                                            <textarea :ref="`script_description_${idx}`" v-model="msg.message" class="border h-10 w-10/12" />
                                            <button @click="chatScriptMessageAdd(idx)"><i class="bi bi-plus-circle-fill text-green-700 w-1/12"></i></button>
                                            <button @click="chatScriptMessageRemove(idx)"><i class="bi bi-dash-circle-fill text-red-700 w-1/12"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="viewChatScriptImport" class="flex flex-col">
                                <label for="chat_script_import" class="flex w-full font-semibold">Raw Script Representation</label>
                                <div>Copy your chat script's saved JSON here:</div>
                                <textarea id="chat_script_import" v-model="chatScriptStored" class="border w-full h-40" />
                            </div>
                            <div class="flex items-center gap-2">
                                <button
                                    class="block text-sm font-medium text-white px-3 py-1 rounded-lg bg-valence-pink-800 hover:bg-valence-pink-800/75"
                                    autofocus="true"
                                    @click="handleChatScriptUse"
                                >
                                    Use Script
                                </button>
                                <button
                                    type="submit"
                                    class="block text-sm font-medium text-white px-3 py-1 rounded-lg bg-valence-pink-800 hover:bg-valence-pink-800/75"
                                    @click="toggleChatScriptImport"
                                >
                                    Import
                                </button>
                                <button
                                    type="submit"
                                    class="block text-sm font-medium text-white px-3 py-1 rounded-lg bg-valence-pink-800 hover:bg-valence-pink-800/75"
                                    @click="handleChatScriptExport"
                                >
                                    Export to clipboard
                                </button>
                                <button
                                    class="block text-sm font-medium text-white px-3 py-1 rounded-lg bg-valence-pink-800 hover:bg-valence-pink-800/75"
                                    autofocus="true"
                                    @click="handleScriptClear"
                                >
                                    Clear Script
                                </button>
                                <div v-show="wasChatScriptExported" class="block text-white p-1 bg-green-700 rounded">Configuration exported to clipboard.</div>
                                <div v-show="wasChatScriptUpdated" class="block text-white p-1 bg-green-700 rounded">Configuration updated.</div>
                            </div>
                        </form>
                    </template>
                    <template v-if="isActive(pane.CLEAR_CACHE)">
                        <form class="flex flex-col gap-4 text-sm text-left" @submit="clearCache">
                            <div v-if="existingCache">
                                <p class="text-xl font-bold mb-4">Existing cache</p>
                                <ul>
                                    <template v-for="(value, key) in existingCache" :key="key">
                                        <li class="mb-2">{{ value }}</li>
                                    </template>
                                </ul>
                            </div>
                            <p class="text-xl font-bold">Clear cache</p>
                            <p>Submitting will clear the cache for all prompts associated with the current user and coaching session.</p>
                            <p v-show="cacheCleared" class="text-green-700 font-bold">Cache cleared!</p>
                            <div>
                                <button class="block text-sm font-medium text-white px-3 py-1 rounded-lg bg-valence-pink-800 hover:bg-valence-pink-800/75">Clear cache</button>
                            </div>
                        </form>
                    </template>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { json } from "@codemirror/lang-json";
import { useStorage } from "@vueuse/core";
import { ACTION, lineIsAction, messageHasActions } from "~vue/chatActions";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { basicSetup, EditorView } from "codemirror";
import { computed, inject, nextTick, onBeforeUnmount, onMounted, reactive, ref, useTemplateRef, watch } from "vue";

const { $sendEvent: sendEvent } = inject("globalProperties");

const ACTION_LIST = Object.values(ACTION).sort();

const PANE = {
    CHAT_SCRIPT: "chat_script",
    ADD_ACTION: "add_action",
    ACTIONS: "actions",
    TRIGGER_TOOL: "trigger_tool",
    CLEAR_CACHE: "clear_cache",
};

const TOOL_LIST = ["ActionItems", "EventIdentifier", "InternalThinking", "SuggestedTopics", "PersonalizedAdvice", "PersonalizedRating", "ValuesInsight"];

const PANE_LIST = [PANE.CHAT_SCRIPT, PANE.ADD_ACTION, PANE.ACTIONS, PANE.TRIGGER_TOOL, PANE.CLEAR_CACHE];

const SAMPLE_PARAMS = {
    [ACTION.ONBOARDING_SUMMARY]: {
        summary: "This is the content of the onboarding summary",
    },
    [ACTION.TIP]: {
        content: "This is a tip, and this is the content of the tip",
        type: "md",
    },
    [ACTION.VALUES_INSIGHT]: {
        final_response: "The contents of the values insight",
    },
    [ACTION.INTERNAL_THINKING]: {
        final_response: "The contents of the internal thinking",
    },
    [ACTION.SUGGESTED_TOPICS]: {
        suggested_topics: [
            {
                topic_name: "Topic A",
            },
            {
                topic_name: "Topic B",
            },
            {
                topic_name: "Topic C",
            },
        ],
    },
    [ACTION.PERSONALIZED_RATING]: {
        num_sessions: 4,
        user_first_name: "Joe",
    },
    [ACTION.LLM_EXPLANATION]: {
        content: "The content of the LLM explanation",
        feedback: null,
    },
    [ACTION.PERSONALIZED_ADVICE]: {
        start_path: "/coach/start/?script=intake-step-1",
    },
    [ACTION.ACTION_ITEMS]: {
        email_summary: "Summary of the action items",
        email_sent: false,
        email_success_message: "Summary sent to your email!",
        action_items: [
            {
                checked: false,
                content: "Action item a",
            },
            {
                checked: false,
                content: "Action item b",
            },
            {
                checked: false,
                content: "Action item c",
            },
        ],
    },
};

const CHAT_MESSAGE_NOTIFICATION_TIMEOUT = 2000;

const getNextUnusedScriptMessage = (activeScript) => {
    return activeScript.messages.find((msg) => !msg.used);
};

const props = defineProps({
    messages: {
        type: Array,
        default: () => [],
    },
    sendMessage: {
        type: Function,
    },
    promptId: {
        type: Number,
    },
});

const chatScriptStorageKey = `chat_script_${props.promptId}`;
const chatScriptActiveStorageKey = `chat_script_${props.promptId}_active`;

// this is the chat script the user can expect to run when activated
const chatScriptStored = useStorage(chatScriptStorageKey, JSON.stringify({ name: "Script", messages: [{ message: "" }] }, null, 2));

// for tracking progress on a current chat script execution
const chatScriptRunning = useStorage(chatScriptActiveStorageKey, JSON.stringify({}));

const toolToRun = reactive({
    tool: undefined,
    error: null,
    loading: false,
});

const newAction = reactive({
    action: undefined,
    messageId: undefined,
    actionParamsEditor: null,
    error: null,
    loading: false,
});

const dialogElement = useTemplateRef("dialogElement");
const actionEditor = useTemplateRef("actionEditor");

const activePane = ref(PANE.CHAT_SCRIPT);

const chatScript = reactive(JSON.parse(chatScriptStored.value));
const chatScriptActive = ref(JSON.parse(chatScriptRunning.value));

const chatScriptInstructionsVisible = ref(false);
const viewChatScriptImport = ref(false);
const wasChatScriptExported = ref(false);
const wasChatScriptUpdated = ref(false);
const isChatScriptRunning = ref(false);
const chatScriptExportInterval = ref(null);
const chatScriptUpdateInterval = ref(null);
const cacheCleared = ref(null);
const existingCache = ref(null);

// TODO: Ask Gabriel why these are computed values, we might just be able to use the raw consts now that we are in
// the composition api
const paneList = computed(() => PANE_LIST);
const pane = computed(() => PANE);
const action = computed(() => ACTION);
const actionList = computed(() => ACTION_LIST);
const toolList = computed(() => TOOL_LIST);

watch(newAction.action, (value, oldValue) => {
    if (value && value !== oldValue) {
        newAction.actionParamsEditor.dispatch({
            changes: {
                from: 0,
                to: newAction.actionParamsEditor.state.doc.length,
                insert: JSON.stringify(SAMPLE_PARAMS[newAction.action], null, 2),
            },
        });
    }
});

watch(chatScript, (newChatScript) => {
    chatScriptStored.value = JSON.stringify(newChatScript, null, 2);
    wasChatScriptUpdated.value = true;
    clearInterval(chatScriptUpdateInterval.value);
    chatScriptUpdateInterval.value = setInterval(() => {
        wasChatScriptUpdated.value = false;
        clearInterval(chatScriptUpdateInterval.value);
    }, CHAT_MESSAGE_NOTIFICATION_TIMEOUT);
});

// This is creating an infinite loop when the chat script is stored
// watch(chatScriptStored, (newChatScript, currChatScript) => {
//     if (!isEqual(currChatScript, JSON.parse(newChatScript))) {//         chatScript.value = JSON.parse(newChatScript);
//     }
// });

watch(chatScriptActive, (newChatScript) => {
    // skip if script copied over already to avoid running on "used" field updates
    if (chatScriptRunning.value) {
        return;
    }
    chatScriptRunning.value = JSON.stringify(newChatScript);
    sendNextChatScriptMessage(messages[messages.length - 1].chat_message_id);
});

// watch(props.messages, (newMessages) => {
//     if (!isChatScriptRunning.value) {
//         return;
//     }
//     let newMessage = newMessages[newMessages.length - 1];
//     if (newMessage.chat_message_id === undefined) {
//         return;
//     }
//     // we only want to send on complete markdown messages
//     if (newMessage.role === "assistant" && newMessage.lines[0].type === "md") {
//         sendNextChatScriptMessage(newMessage.chat_message_id);
//     }
// });

onMounted(() => {
    if (dialogElement.value) {
        dialogElement.value.addEventListener("cancel", close);
        document.addEventListener("keydown", handleShortcut);
    }

    newAction.actionParamsEditor = initializeActionParamsEditor();

    getCache();
});

onBeforeUnmount(() => {
    if (dialogElement.value) {
        dialogElement.value.removeEventListener("cancel", close);
        document.removeEventListener("keydown", handleShortcut);
    }

    if (newAction.actionParamsEditor) {
        newAction.actionParamsEditor.destroy();
    }
});

function initializeActionParamsEditor() {
    return new EditorView({
        doc: JSON.stringify({}, null, 2),
        extensions: [basicSetup, json()],
        parent: actionEditor.value,
    });
}

function handleTriggerTool(e) {
    e.preventDefault();

    toolToRun.error = null;

    if (!toolToRun.tool) {
        toolToRun.error = new Error("Must pick tool");
        return;
    }

    toolToRun.loading = true;
    sendEvent("run_tool", {
        tool: toolToRun.tool,
    })
        .then(() => {
            toolToRun.tool = undefined;
            toolToRun.error = null;
            toolToRun.loading = false;
        })
        .catch((e) => {
            toolToRun.error = e;
            toolToRun.loading = false;
        });
}

function handleAddAction(e) {
    e.preventDefault();
    newAction.error = null;
    let params = {};

    try {
        if (!newAction.action) {
            throw new Error("Missing action name");
        }

        if (!newAction.messageId) {
            throw new Error("Missing action message");
        }

        const paramsString = newAction.actionParamsEditor.state.doc.toString();
        params = JSON.parse(paramsString);
    } catch (e) {
        newAction.error = e;
        return;
    }

    newAction.loading = true;

    sendEvent("create_action", {
        chat_message_id: newAction.messageId,
        type: newAction.action,
        action_params: params,
        action_state: {
            dismissed: false,
            submitted: false,
        },
    })
        .then(() => {
            newAction.actionParamsEditor.destroy();
            newAction.action = undefined;
            newAction.messageId = undefined;
            newAction.actionParamsEditor = initializeActionParamsEditor();
            newAction.error = null;
            newAction.loading = false;
        })
        .catch((e) => {
            newAction.error = e;
            newAction.loading = false;
        });
}

function handleChatScriptUse() {
    isChatScriptRunning.value = true;

    // TODO: Figure out what this is doing
    Object.assign(chatScriptActive, chatScript, { responded: [], messages: chatScript.messages.map((msg) => ({ ...msg, used: false })) });

    close();
}

function handleChatScriptExport() {
    navigator.clipboard.writeText(JSON.stringify(chatScript));
    wasChatScriptExported.value = true;
    clearInterval(chatScriptExportInterval.value);
    chatScriptExportInterval.value = setInterval(() => {
        wasChatScriptExported.value = false;
        clearInterval(chatScriptExportInterval.value);
    }, CHAT_MESSAGE_NOTIFICATION_TIMEOUT);
}

function handleScriptClear() {
    chatScriptStored.value = JSON.stringify({ name: "Script", messages: [{ message: "" }] }, null, 2);

    // Because chatScript is using `reactive` we can't replace the entire object here, and have to update
    // the properties individually
    chatScript.name = "Script";
    chatScript.messages = [{ message: "" }];
}

function toggleChatScriptImport() {
    viewChatScriptImport.value = !viewChatScriptImport.value;
}

function toggleChatScriptInstructionsVisibility() {
    chatScriptInstructionsVisible.value = !chatScriptInstructionsVisible.value;
}

function chatScriptMessageAdd(index) {
    chatScript.messages.splice(index + 1, 0, { message: "" });
}

function chatScriptMessageRemove(index) {
    if (chatScript.messages.length > 1) {
        chatScript.messages.splice(index, 1);
    }
}

function getMessagePreview(message, previewLength = 75) {
    const mdLine = message.lines.find((l) => l.type === "md");

    if (!mdLine) {
        return "";
    }

    let preview = `(${message.role}) ${mdLine.content.substr(0, previewLength)}`;

    if (mdLine.content.length > previewLength) {
        preview += `...`;
    }

    return preview;
}

function setPane(p) {
    activePane.value = p;
}

function isActive(p) {
    return activePane.value === p;
}

function getPaneTitle(pane) {
    switch (pane) {
        case PANE.ADD_ACTION:
            return "New action";
        case PANE.ACTIONS:
            return "Loaded actions";
        case PANE.TRIGGER_TOOL:
            return "Run tool";
        case PANE.CHAT_SCRIPT:
            return "Chat script";
        case PANE.CLEAR_CACHE:
            return "Clear cache";
    }

    return null;
}

function sendNextChatScriptMessage(triggerMsgId) {
    // skip if the trigger message has already been responded to
    if (chatScriptActive.value.responded.includes(triggerMsgId)) {
        return;
    }

    let msg = getNextUnusedScriptMessage(chatScriptActive);
    if (!msg) {
        return;
    }
    // empty messages allow skipping a response, e.g. waiting for system message insertion
    if (msg.message) {
        $props.sendMessage(msg.message, {});
    }
    chatScriptActive.value.responded.unshift(triggerMsgId);
    msg.used = true;
}

function handleShortcut(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === ",") {
        if (dialogElement?.value.open) {
            close();
        } else {
            open();
        }
    }
}

async function getCache() {
    const coachingSessionId = window.config.getActiveSessionId();

    const response = await fetch(`/api/prompts/${coachingSessionId}/cache`);
    const json = await response.json();

    existingCache.value = json.cache;
}

async function clearCache(event) {
    event.preventDefault();

    const coachingSessionId = window.config.getActiveSessionId();

    const response = await fetch(`/api/prompts/${coachingSessionId}/cache`, {
        method: "DELETE",
        headers: { "X-CSRFToken": getCookie("csrftoken") },
    });

    if (response.ok) {
        cacheCleared.value = true;
        getCache();
        setTimeout(() => {
            cacheCleared.value = false;
        }, 2000);
    }
}

function open() {
    nextTick(() => {
        if (dialogElement) {
            dialogElement.value.showModal();
        }
    });
}

function close() {
    dialogElement.value.close();
}
</script>

<style type="postcss">
dialog#devtools-dialog::backdrop {
    background-color: #000;
    opacity: 0.2;
}
</style>
