<template>
    <button
        type="button"
        :class="dictationOn ? 'text-valence-pink-600 hover:text-valence-pink-600/80' : 'text-valence-grey-600 hover:text-valence-grey-600/80'"
        class="tooltip tooltip-bottom"
        :data-tip="voiceDictationText"
        @click="useChatStore.toggleDictation"
    >
        <i :class="dictationOn ? 'bi-volume-up-fill' : 'bi-volume-mute-fill'" class="bi text-3xl"></i>
    </button>
</template>

<script setup>
import { useChatStore } from "~vue/stores/chatStore.js";
import { computed } from "vue";

const dictationOn = computed(() => useChatStore.dictationOn);
const voiceDictationText = computed(() => (dictationOn.value ? "Turn Off Voice" : "Turn On Voice"));
</script>
