<template>
    <div class="flex flex-col gap-5 my-5">
        <p v-if="tool_call.parameters.preamble">{{ tool_call.parameters.preamble }}</p>
        <ul class="flex gap-5 flex-col !ml-0">
            <template v-for="(point, idx) in tool_call.parameters.bullet_points" :key="idx">
                <Transition name="fade" appear>
                    <li :key="idx" class="bg-white p-4 list-none rounded-xl flex flex-col gap-4">
                        <h3 class="text-xl font-bold">{{ point.title }}</h3>
                        <p>{{ point.content }}</p>
                        <div class="flex justify-between">
                            <button
                                class="p-2 px-4 rounded-xl text-sm"
                                :disabled="coachedOn !== null"
                                :class="coachedOn !== null ? 'bg-valence-grey-50' : 'bg-valence-pink-50 cursor-pointer'"
                                @click="coachMe(point.content, idx)"
                            >
                                Coach me on this
                                <span
                                    class="inline-block h-5 w-5 ml-2 rounded-full rounded-full relative translate-y-1"
                                    :class="coachedOn !== null ? 'bg-valence-grey-600' : 'bg-valence-pink-600'"
                                >
                                    <i class="bi bi-arrow-right-short text-lg text-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></i>
                                </span>
                            </button>
                            <button :disabled="savedForLater.includes(idx)" class="italic text-sm text-valence-grey-400 cursor-pointer" @click="saveForLater(point.content, idx)">
                                <span v-if="savedForLater.includes(idx)">Saved for later</span>
                                <span v-else>Save this as a topic to revisit</span>
                                <i class="bi inline-block translate-y-0.5 text-lg ml-2" :class="savedForLater.includes(idx) ? 'bi-bookmark-fill' : 'bi-bookmark'"></i>
                            </button>
                        </div>
                    </li>
                </Transition>
            </template>
        </ul>
        <p v-if="tool_call.parameters.closing">{{ tool_call.parameters.closing }}</p>
    </div>
</template>

<script>
export default {
    name: "CoachableBulletPoints",
    components: {},
    props: {
        tool_call: {
            type: Object,
            default() {
                return {};
            },
        },
        messageId: {
            type: Number,
            default: 0,
        },
        lineIdx: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            coachedOn: this.tool_call?.tool_state?.coach_me || null,
            savedForLater: this.tool_call?.tool_state?.saved_topics || [],
        };
    },
    methods: {
        coachMe(content, bullet_index) {
            this.coachedOn = bullet_index;

            this.emitter.emit("coachable_bullet_points_coach_me", {
                action_params: {
                    coach_me: { content, bullet_index },
                },
                message_id: this.messageId,
                lineIdx: this.lineIdx,
                coaching_session_id: document.getElementById("app").dataset.coachingSessionId,
            });
        },
        saveForLater(content, bullet_index) {
            this.savedForLater.push(bullet_index);

            this.emitter.emit("coachable_bullet_points_save_for_later", {
                action_params: {
                    save_for_later: { content, bullet_index },
                },
                message_id: this.messageId,
                lineIdx: this.lineIdx,
                coaching_session_id: document.getElementById("app").dataset.coachingSessionId,
            });
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1000 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
