<template>
    <h1 class="text-black text-lg font-extrabold mt-4">Content</h1>
    <div v-if="!files.length" class="border rounded-lg pt-4 pb-2 px-4">
        <div class="relative h-16 mb-6">
            <div class="absolute rounded-lg bg-white shadow-inner w-full h-10 z-20 p-8 flex justify-around items-center">
                <div class="rounded-full bg-gray-100 h-10 w-10 flex items-center justify-center">
                    <i class="bi bi-file-earmark-text text-xl text-gray-500"></i>
                </div>
            </div>
            <div class="absolute bottom-[-10px] left-1/2 transform -translate-x-1/2 rounded-lg bg-gray-50 h-10 w-11/12 z-10 blur-sm"></div>
            <div class="absolute bottom-[-20px] left-1/2 transform -translate-x-1/2 rounded-lg bg-gray-100 h-10 w-10/12 z-0 blur-sm"></div>
        </div>
        <div class="flex flex-col gap-2 text-center text-gray-500">
            <i>No content added yet</i>
            <i class="text-xs">Paste your project briefs, work details, meeting notes, or relevant documents.</i>
        </div>
    </div>
    <div v-else class="border rounded-lg pt-4 pb-2 px-4 flex flex-col gap-4">
        <div v-for="file in files" :key="file.name" class="flex flex-col gap-2">
            <div class="flex gap-2 cursor-pointer" @click="showFileContent(file)">
                <div class="border bg-white rounded-lg h-12 w-16 flex items-center justify-center">
                    <i class="bi bi-file-earmark-text text-xl text-gray-500"></i>
                </div>
                <div class="flex flex-col">
                    <span class="text-black text-sm font-medium">{{ file.name.includes("pasted-content") ? "Pasted Content" : file.name }}</span>
                    <span class="text-xs">{{ showContentForFile === file.name ? "Click to hide" : "Click to view full content" }}</span>
                </div>
            </div>
            <div v-if="showContentForFile === file.name" class="px-2">
                <p class="text-xs">{{ file.content }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";

const props = defineProps({
    messages: {
        type: Array,
        default: () => [],
    },
});

const files = computed(() => {
    return props.messages?.reduce((acc, message) => {
        const file = message.lines?.find((line) => line.action_name === "file_upload");
        if (file) {
            acc.push(file.action_params[0]);
        }
        return acc;
    }, []);
});

const showContentForFile = ref(null);
const showFileContent = (file) => {
    showContentForFile.value = showContentForFile.value === file.name ? null : file.name;
};
</script>
