<template>
    <div class="hidden md:flex h-full flex-col-reverse md:max-w-[345px] overflow-y-hidden">
        <div ref="floatControl" class="z-20 top-0 right-0 absolute flex justify-end items-center py-[19px] gap-4 md:min-w-[338px] pl-2 pr-5">
            <DictationControl />
            <button
                v-if="shouldShowWrapUp"
                class="flex-grow bg-white rounded-lg p-2 text-blue-600 text-xs font-medium border border-blue-600 border-sm transition-colors hover:bg-gray-50/60"
                @click="emitter.emit('wrap_up_chat')"
            >
                Wrap up &amp; recap
            </button>
            <div class="self-end">
                <ChatWidgetListToggle :is-open="showWidgetList" @click="$emit('toggleShowWidgetList')" />
            </div>
        </div>
        <div
            v-show="showWidgetList"
            :style="sidebarControlDynamicStyle"
            class="grow flex-col w-full md:w-1/5 md:min-w-[320px] md:flex bg-valence-blue-background border border-valence-grey-300 rounded-[20px] ml-2 mb-2 max-h-full overflow-y-auto"
        >
            <div v-show="!expandedWidget" class="p-4">
                <ChatWidgetList
                    :role-play-adjustment-default="rolePlayAdjustmentDefault"
                    :role-play-adjustments="rolePlayAdjustments"
                    :messages="messages"
                    :is-open="showWidgetList"
                    @expand="handleExpand"
                />
            </div>
            <div v-if="expandedWidget" class="overflow-hidden max-h-full flex flex-col py-4">
                <button
                    title="Return"
                    type="button"
                    class="mb-2 flex items-center w-full shrink-0 px-4 font-medium text-valence-grey-600 hover:text-valence-grey-600/80"
                    @click="expandedWidget = null"
                >
                    <span class="bi bi-chevron-left mr-1"></span>
                    {{ expandedWidget.title }}
                </button>
                <component :is="expandedWidget.component" v-bind="expandedWidget.props" :messages="messages" />
            </div>
        </div>
    </div>
</template>

<script setup>
import DictationControl from "~vue/ChatWidgets/DictationControl.vue";
import { computed, inject, markRaw, ref } from "vue";

import ChatWidgetList from "./ChatWidgetList.vue";
import ChatWidgetListToggle from "./ChatWidgetListToggle.vue";

const props = defineProps({
    rolePlayAdjustmentDefault: {
        type: String,
        default: () => "",
    },
    rolePlayAdjustments: {
        type: Array,
        default: () => [],
    },
    messages: {
        type: Array,
        default: () => [],
    },
    showWidgetList: {
        type: Boolean,
        default: true,
    },
});

const { emitter } = inject("globalProperties");

const shouldShowWrapUp = computed(() => {
    return props.messages.filter((m) => m.role === "user").length >= 2;
});

const emit = defineEmits(["toggleShowWidgetList"]);

// { title, component, props }
const expandedWidget = ref(null);
const floatControl = ref(null);

function handleExpand(widget) {
    if (!props.showWidgetList) {
        emit("toggleShowWidgetList");
    }

    if (!expandedWidget.value) {
        expandedWidget.value = {
            component: markRaw(widget.component),
            title: widget.title,
            props: widget.props,
        };
    }
}

const sidebarControlDynamicStyle = computed(() => {
    if (!floatControl.value || !(floatControl.value instanceof HTMLElement)) {
        // Fall back to full height if floating controls are not in the DOM
        return "max-height: 100vh;";
    }

    const { height } = floatControl.value.getBoundingClientRect();
    return `max-height: calc(100vh - ${height}px);`;
});
</script>
