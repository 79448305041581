<template>
    <div
        v-if="!data.action_state || !data.action_state.submitted"
        class="w-full grid grid-cols-1 md:grid-cols-2 gap-2 justify-end relative"
        :class="role == 'user' ? 'justify-end' : 'justify-start'"
    >
        <button v-if="page > 0" class="hidden 2xl:block absolute -left-16 top-1/2 -translate-y-1/2" @click="previousPage">
            <i class="bi bi-chevron-left p-2 bg-white rounded-full"></i>
        </button>
        <template v-for="(scenario, idx) in currentScenarios" :key="page * 4 + idx">
            <BaseActionCard @click="chooseScenario(scenario)">
                <div class="grow flex flex-col gap-4 justify-between h-full">
                    <div class="flex flex-col gap-1 grow">
                        <div class="text-base font-bold">
                            {{ scenario.title }}
                        </div>
                        <p v-if="scenario.description" class="text-sm">{{ scenario.description }}</p>
                    </div>
                    <div>
                        <span v-if="scenario.is_user_topic && scenario.created_at" class="shrink-0">
                            <span class="rounded-[3px] uppercase text-xs p-1 px-2 font-semibold bg-valence-light-blue-50 mr-2">
                                Saved on {{ formatDate(scenario.created_at) }}
                            </span>
                        </span>
                        <span v-if="scenario.estimated_time" class="shrink-0">
                            <span class="rounded-[3px] uppercase text-xs p-1 px-2 font-semibold bg-valence-violet-50">{{ scenario.estimated_time }} min</span>
                        </span>
                    </div>
                </div>
            </BaseActionCard>
        </template>
        <button v-if="page < totalPages - 1" class="hidden 2xl:block absolute -right-16 top-1/2 -translate-y-1/2" @click="nextPage">
            <i class="bi bi-chevron-right p-2 bg-white rounded-full"></i>
        </button>
    </div>
    <div class="2xl:hidden w-full mt-8 relative">
        <button v-if="page > 0" class="absolute left-0" @click="previousPage">
            <i class="bi bi-chevron-left p-2 bg-white rounded-full"></i>
        </button>
        <button v-if="page < totalPages - 1" class="absolute right-0" @click="nextPage">
            <i class="bi bi-chevron-right p-2 bg-white rounded-full"></i>
        </button>
    </div>
</template>

<script setup>
import BaseActionCard from "~vue/components/BaseActionCard.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { computed, inject, onMounted, ref } from "vue";

const SCENARIOS_PER_PAGE = 4;

const { emitter } = inject("globalProperties");

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    role: {
        type: String,
        default: "user",
    },
    messageId: {
        type: Number,
        default: 0,
    },
    lineIdx: {
        type: Number,
        default: 0,
    },
});

const page = ref(0);
const currentScenarios = computed(() => props.data.action_params.scenarios.slice(page.value * SCENARIOS_PER_PAGE, (page.value + 1) * SCENARIOS_PER_PAGE));
const totalPages = computed(() => Math.ceil(props.data.action_params.scenarios.length / SCENARIOS_PER_PAGE));

onMounted(() => {
    logUserInteraction("scenario_presented", { chat_message_id: props.messageId });
});

const nextPage = () => {
    page.value = page.value >= totalPages.value - 1 ? 0 : page.value + 1;
};

const previousPage = () => {
    page.value = page.value <= 0 ? totalPages.value - 1 : page.value - 1;
};

const chooseScenario = (scenario) => {
    emitter.emit("choose_scenario", {
        answer: scenario.title,
        script: scenario.script,
        start_url: scenario.start_url,
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_state: {
            dismissed: false,
            submitted: true,
        },
    });
    logUserInteraction("scenario_chosen");
};

const formatDate = (date) => {
    if (!date) {
        return "";
    }
    return DateTime.fromISO(date).toLocaleString({ month: "short", day: "numeric" });
};
</script>

<style type="postcss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 200 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
