<template>
    <ChatWidgetFrame icon="bi-pencil-square" title="I'm Taking Notes" :minimized="data.action_state.minimized" @widget-toggle-visibility="toggleVisibility">
        <p class="text-sm">{{ data?.action_params?.summary }}</p>
    </ChatWidgetFrame>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject, ref } from "vue";

import ChatWidgetFrame from "./ChatWidgetFrame.vue";

const props = defineProps({
    data: Object({}),
    role: String("user"),
    messageId: Number(0),
    lineIdx: Number(0),
});

const { emitter } = inject("globalProperties");

const isOpen = ref(false);

function toggleVisibility() {
    isOpen.value = !isOpen.value;

    emitter.emit("onboarding_summary", {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_state: {
            minimized: !isOpen.value,
        },
    });

    logUserInteraction("onboarding_summary_minimize", { minimized: !isOpen.value });
}
</script>
