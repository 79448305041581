<template>
    <ChatWidgetFrame theme="blue" title="Action items from conversation" icon="bi-clipboard2" :minimized="data.action_state.minimized" @widget-toggle-visibility="toggleVisibility">
        <ActionItemsList :action-items="data.action_params.action_items" :message-id="messageId" :line-idx="lineIdx" :data="data" />
        <Button v-if="!data.action_params.email_sent" @click="openEmailDialog"> Send me a summary </Button>
        <div v-else class="text-xs text-center">{{ data.action_params.email_success_message }}</div>
    </ChatWidgetFrame>
    <ChatActionItemsEmailDialog v-if="!data.action_params.email_sent" ref="emailDialog" :summary="data.action_params.email_summary" @complete="send" />
</template>

<script setup>
import Button from "~vue/components/BaseButton.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject, ref } from "vue";

import ActionItemsList from "./ActionItemsList.vue";
import ChatActionItemsEmailDialog from "./ChatActionItemsEmailDialog.vue";
import ChatWidgetFrame from "./ChatWidgetFrame.vue";

const props = defineProps({
    data: Object({}),
    role: String("user"),
    messageId: Number(0),
    lineIdx: Number(0),
});

const isMinimized = ref(false);
const emailDialog = ref(null);

const { emitter } = inject("globalProperties");

function openEmailDialog() {
    emailDialog.value.open();
}

function send(editedSummary) {
    const action_data = {
        subaction: "send_email_summary",
        email_summary: editedSummary,
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_params: {
            ...props.data.action_params,
            email_sent: true,
        },
        action_state: {
            submitted: false,
        },
    };
    emailDialog.value.dismiss();
    emitter.emit("action_items", action_data);
    logUserInteraction("action_items_email_sent");
}

function toggleVisibility() {
    isMinimized.value = !isMinimized.value;
    emitter.emit("action_items", {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_state: {
            minimized: isMinimized.value,
            submitted: false,
        },
    });
    logUserInteraction("action_items_minimize", { minimized: isMinimized.value });
}
</script>
