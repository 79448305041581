<template>
    <div class="relative flex flex-col md:flex-row grow overflow-hidden max-h-full text-valence-grey-800">
        <div id="chat-container" class="relative grow flex flex-col overflow-hidden max-h-full" :class="!showWidgetSidebar && 'mr-[-15px]'">
            <DevTools v-if="devtoolsEnabled" :messages="messages" :send-message="sendMessage" :prompt-id="first_prompt_id" />
            <ExitIntentDialog ref="exitIntentDialogRef" :messages="messages" :has-future-calendar-events="has_future_calendar_events" />
            <SessionSummary ref="sessionSummaryRef" :messages="messages" :coaching-session-id="cs_id" />
            <ThoughtProcessModal :messages="messages" />
            <div :class="{ 'grow flex': showWidgetSidebar }" class="shrink-0 md:hidden flex flex-col justify-end overflow-hidden max-h-full border-b border-b-gray-200">
                <div class="border-t border-t-gray-200 shrink-0 flex bg-white text-sm font-medium">
                    <button v-if="showWidgetSidebar" class="flex items-center gap-4 px-4 py-2 w-full" type="button" @click="showWidgetSidebar = false">
                        <span role="presentation" class="bi bi-chevron-up"></span>
                        Close
                    </button>
                    <button v-if="!showWidgetSidebar" title="Open menu" class="flex items-center gap-4 px-4 py-2 w-full" type="button" @click="showWidgetSidebar = true">
                        <span role="presentation" class="bi bi-chevron-down"></span>
                        <div class="flex gap-1">Suggestions</div>
                    </button>
                </div>
                <div v-if="showWidgetSidebar" class="overflow-y-auto border-t border-t-gray-200 bg-white grow p-4">
                    <ChatWidgetList :messages="raw_messages" />
                </div>
            </div>
            <div class="mt-5">
                <Disclaimer v-if="!isMicrosoftTeams" :message="disclaimer_message" />
            </div>
            <ChatMessageList
                ref="chatMessageList"
                :loading-message-stack="loading_message_stack"
                :dismiss-suggested-scenarios="dismissSuggestedScenarios"
                :messages="messages"
                :number-of-messages-at-init="numberOfMessagesAtInit"
                :show-failed-response="show_failed_response"
                :show-start-loading-message="showStartLoadingMessage"
                :show-slow-response="show_slow_response"
                :show-widget-sidebar="showWidgetSidebar"
                :waiting="waiting_for_assistant"
                @dismiss-failure="show_failed_response = false"
                @loading-complete="handleLoadingMessageComplete"
            />
            <div v-if="chatError" class="w-full md:max-w-screen-md md:mx-auto shrink-0">
                <ChatErrorMessage>
                    {{ chatError }}
                </ChatErrorMessage>
            </div>
            <ChatActionFollowupInChat v-if="showInChatFollowUp" :data="inChatFollowUp" />
            <div class="-mb-4">
                <Disclaimer v-if="isMicrosoftTeams" :message="disclaimer_message" />
            </div>
            <ChatMessageControls
                ref="chatMessageControls"
                :is-sending="waiting_for_assistant"
                :is-transcribing="waiting_for_transcript"
                :recording-button-text="startRecordingButtonText"
                :show-slow-transcription="show_slow_transcription"
                :text-placeholder="textPlaceholder"
                :recording-onboarding-enabled="recording_onboarding_enabled"
                @recording-complete="handleRecordingComplete"
                @recording-error="handleRecordingError"
                @recording-start="handleRecordingStart"
                @textbox-show="handleTextboxShown"
                @text-keydown="handleKeyDown"
                @text-send="handleSendMessage"
                @transcription-cancel="cancelTranscription"
            />
        </div>
        <aside>
            <ChatWidgetSidebar
                :role-play-adjustments="role_play_adjustments"
                :role-play-adjustment-default="role_play_adjustment_default"
                :show-widget-list="showWidgetSidebar"
                :messages="raw_messages"
                @toggle-show-widget-list="handleToggleWidgetSidebar"
            />
        </aside>
    </div>
    <CookieBanner />
</template>

<script>
import ChatActionFollowupInChat from "~vue/ChatActionFollowupInChat.vue";
import { ACTION, lineIsWidget, pluckAction } from "~vue/chatActions.js";
import ChatErrorMessage from "~vue/ChatErrorMessage.vue";
import ChatMessageList from "~vue/ChatMessageList.vue";
import ChatWidgetList from "~vue/ChatWidgetList.vue";
import ChatMessageControls from "~vue/ChatWidgets/ChatMessageControls.vue";
import ChatWidgetSidebar from "~vue/ChatWidgetSidebar.vue";
import CookieBanner from "~vue/components/CookieBanner.vue";
import { ONBOARDING_TASK } from "~vue/components/OnboardingChecklist.vue";
import DevTools from "~vue/DevTools.vue";
import Disclaimer from "~vue/Disclaimer.vue";
import { CHAT_EVENT } from "~vue/events.js";
import ExitIntentDialog from "~vue/ExitIntentDialog.vue";
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
import SessionSummary from "~vue/SessionSummary.vue";
import SocketClient from "~vue/SocketClient.js";
import { useChatStore } from "~vue/stores/chatStore.js";
import { useUserStore } from "~vue/stores/userStore.js";
import ThoughtProcessModal from "~vue/ThoughtProcessModal.vue";
import { isInIFrame } from "~vue/utils.js";
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { ref } from "vue";

import { encodeAudio, transcribeRecording } from "../../transcription.js";
import { getRandomInt } from "../utils/random.js";

/* How much time to wait for first socket message to appear before showing
 * loading message at the start of the session.
 */
const START_LOADING_MESSAGE_THRESHOLD_MS = 300;
const SLOW_MESSAGE_TIMEOUT_MS = 2000;
const FAILED_MESSAGE_TIMEOUT_MS = 55000;

const CHAT_ERROR = {
    TRANSCRIPTION_EXHAUSTED: "We're having issues transcribing your audio message. Please try again later.",
    RECORDING_DEFAULT: "We're having issues recording your audio message. Please try again later.",
    SEND_FAILURE: "We're having issues sending your message. Please try again later.",
};

export default {
    name: "ChatComponent",
    components: {
        ChatErrorMessage,
        ChatMessageControls,
        ChatMessageList,
        ChatWidgetList,
        ChatWidgetSidebar,
        ExitIntentDialog,
        SessionSummary,
        Disclaimer,
        ChatActionFollowupInChat,
        CookieBanner,
        ThoughtProcessModal,
        DevTools,
    },
    layout: LeftSidebarLayout,
    props: {
        role_play_adjustment_default: {
            type: String,
            default: () => "",
        },
        role_play_adjustments: {
            type: Array,
            default: () => [],
        },
        event_context: {
            type: Object,
            default: () => {},
        },
        ws_url: {
            type: String,
            default: () => "",
        },
        recording_onboarding_enabled: {
            type: Boolean,
            default: () => false,
        },
        loading_message_stack: {
            type: Array,
            default: () => [],
        },
        is_start_loading_message_enabled: {
            type: Boolean,
            default: () => false,
        },
        disclaimer_message: {
            type: String,
            default: () => "",
        },
        cs_id: {
            type: String,
            default: () => "",
        },
        has_future_calendar_events: {
            type: Boolean,
            default: () => false,
        },
        has_team_member_profile_context: {
            type: Boolean,
            default: false,
        },
        user_team_members: {
            type: Array,
            default: () => [],
        },
        first_prompt_id: {
            type: Number,
        },
        dev_tools_enabled: {
            type: Number,
        },
    },
    setup() {
        const raw_messages = ref([]);
        const messages = ref([]);
        const waiting_for_transcript = ref(false);
        const waiting_for_assistant = ref(false);
        const show_slow_response = ref(false);
        const show_failed_response = ref(false);
        const show_slow_transcription = ref(false);
        const has_taken_action = ref(false);
        const slow_response_timeout = ref(null);
        const failed_response_timeout = ref(null);
        const backgrounded_at = ref(null);
        const rolePlayAdjust = ref(null);
        const dismissSuggestedScenarios = ref(false);
        const showStartLoadingMessage = ref(false);
        const startLoadingMessageTimeout = ref(null);
        const firstSocketMessageReceived = ref(false);
        const numberOfMessagesAtInit = ref(0);
        const showWidgetSidebar = ref(false);
        const chatError = ref(null);
        const promptSwitchCallCode = ref(null);
        const socketClient = ref(null);
        const showInChatFollowUp = ref(false);
        const inChatFollowUp = ref(null);
        const showExitIntentDialog = ref(false);
        const isMicrosoftTeams = ref(isInIFrame()); // kinda hacky check to determine if in a teams instance

        return {
            raw_messages,
            messages,
            slow_response_timeout,
            failed_response_timeout,
            waiting_for_transcript,
            waiting_for_assistant,
            show_slow_response,
            show_failed_response,
            show_slow_transcription,
            backgrounded_at,
            has_taken_action,
            dismissSuggestedScenarios,
            showStartLoadingMessage,
            startLoadingMessageTimeout,
            firstSocketMessageReceived,
            numberOfMessagesAtInit,
            showWidgetSidebar,
            chatError,
            promptSwitchCallCode,
            rolePlayAdjust,
            socketClient,
            showInChatFollowUp,
            inChatFollowUp,
            showExitIntentDialog,
            isMicrosoftTeams,
        };
    },
    data() {
        return {
            feedbackMailto: this.$feedbackMailto,
            devtoolsEnabled: this.dev_tools_enabled || import.meta.env.DEV,
        };
    },
    computed: {
        startRecordingButtonText() {
            return "Reply now";
        },
        textPlaceholder() {
            if (this.raw_messages.some((message) => message.role === "user")) {
                return "Let's chat...";
            }
            return "Tell me about a challenge you’re facing this week...";
        },
        dictationOn() {
            return useChatStore.dictationOn;
        },
    },
    watch: {
        chatError(current, old) {
            if (current && !old) {
                this.emitter.emit(CHAT_EVENT.OPEN_TEXT_INPUT);
            }
        },
        has_team_member_profile_context: {
            handler(newValue) {
                useChatStore.setHasTeamMemberContext(newValue);
            },
            immediate: true,
        },
        user_team_members: {
            handler(teamMembers) {
                useUserStore.setTeamMembers(teamMembers);
            },
            immediate: true,
        },
    },
    created() {
        document.addEventListener("visibilitychange", this.handleVisibility, false);
        window.addEventListener("beforeunload", this.handleUnload);
    },
    unmounted() {
        this.$setEventContext({});
        this.emitter.emit(CHAT_EVENT.LEAVING_CHAT);
        if (this.socketClient) {
            this.socketClient.removeListeners();
            this.socketClient.instance.close();
        }
        this.emitter.off("user_switched_coaching_mode");
        this.emitter.off("wrap_up_chat");
    },
    mounted() {
        this.$setEventContext(this.event_context);
        if (this.ws_url) {
            this.socketClient = new SocketClient(this.ws_url, {
                reconnectEnabled: true,
                reconnectInterval: 2000,
            });
            this.socketClient.connect();
            let firstMessageReceived = false;

            this.socketClient.onMessage = (msg) => {
                const message_data = JSON.parse(msg.data);
                if (!firstMessageReceived) {
                    firstMessageReceived = true;
                    this.emitter.emit("first_socket_message");
                }
                if (message_data.type) {
                    this.emitter.emit(message_data.type, message_data);
                }
            };
            this.socketClient.onOpen = () => {
                this.emitter.emit("socket_opened");
                this.$sendEvent("initialize_chat", {
                    dictation_on: this.$storage.getStorageSync("dictation_on", true),
                });
            };
            this.socketClient.onError = () => {
                window.Sentry.captureException(new Error("Could not connect to websocket."));
            };
        }

        logUserInteraction("first_paint");
        this.emitter.on("chat_message", this.handleChatMessage);
        this.emitter.on("chat_action", this.handleChatAction);
        this.emitter.on("transcription_event", this.handleTranscriptionEvent);
        this.emitter.on("chat_partial_addition", this.handleChatPartialAddition);
        this.emitter.on("initialize_chat", this.handleChatInit);
        this.emitter.on("choose_answer", this.handleChooseAnswer);
        this.emitter.on("choose_scenario", this.handleChooseScenario);
        this.emitter.on("choose_followup", this.handleChooseFollowup);
        this.emitter.on("coachable_bullet_points_coach_me", this.handleCoachableBulletPointsCoachMe);
        this.emitter.on("coachable_bullet_points_save_for_later", this.handleCoachableBulletPointsSaveForLater);
        this.emitter.on("followup_update", this.handleFollowupChange);
        this.emitter.on("tip_action", this.handleTipAction);
        this.emitter.on("onboarding_summary", this.handleOnboardingSummary);
        this.emitter.on("profile_question", this.handleProfileQuestion);
        this.emitter.on("internal_thinking", this.handleInternalThinking);
        this.emitter.on("values_insight", this.handleValuesInsight);
        this.emitter.on("personalized_advice", this.handlePersonalizedAdvice);
        this.emitter.on("personalized_rating", this.handlePersonalizedRating);
        this.emitter.on(ACTION.ROLE_PLAY_ADJUST, this.handleRolePlayAdjust);
        this.emitter.on("suggested_topics", this.handleSuggestedTopics);
        this.emitter.on("action_items", this.handleActionItems);
        this.emitter.on("socket_opened", this.handleSocketOpened);
        this.emitter.on("first_socket_message", this.handleFirstSocketMessage);
        this.emitter.on(CHAT_EVENT.FOCUS_WIDGET, this.handleWidgetFocus);
        this.emitter.on(CHAT_EVENT.MINIMIZE_WIDGETS, this.handleMinimizeWidgets);
        this.emitter.on("llm_explanation_feedback", this.handleLLMExplanationFeedback);
        this.emitter.on("inferred_profile_answer", this.handleInferredAnswer);
        this.emitter.on("onboarding_followup_scheduled", this.handleOnboardingFollowup);
        this.emitter.on("profile_notification_changed", this.handleProfileNotification);
        this.emitter.on("coaching_mode_updated", this.handleCoachingModeUpdate);
        this.emitter.on("user_switched_coaching_mode", this.handleUserSwitchedCoachingMode);
        this.emitter.on("wrap_up_chat", this.handleWrapUpChat);
        // this websocket event is emitted from `return_team_member_context` in `vpoc/apps/events/tasks.py`
        this.emitter.on("team_member_context", this.handleTeamMemberContext);

        if (window.innerWidth > 768) {
            this.showWidgetSidebar = true;
        }

        if (this.$userDetails.value.email?.endsWith("@valence.co")) {
            document.addEventListener("keydown", this.handlePromptShortcutKeyDown);
        }
    },
    methods: {
        bufferUserActionsToAssistantMessages(messages) {
            const _messages = JSON.parse(JSON.stringify(messages));
            const actionsToBuffer = ["followup", "action_items"];
            const processedMessages = new Set();

            for (let i = 0; i < _messages.length; i++) {
                const currentMessage = _messages[i];
                if (currentMessage.role !== "user" || !currentMessage.lines) {
                    continue;
                }

                let actions = [];
                const linesWithNoActions = [];

                for (const line of currentMessage.lines) {
                    if (line.type === "action" && actionsToBuffer.includes(line.action_name)) {
                        actions.push(line);
                    } else {
                        linesWithNoActions.push(line);
                    }
                }
                // Replace user message lines with ones with no actions
                currentMessage.lines = linesWithNoActions;

                // If actions were found, push them to the next assistant message
                if (actions.length === 0) {
                    continue;
                }

                for (let j = i + 1; j < _messages.length; j++) {
                    const nextMessage = _messages[j];
                    if (nextMessage.role === "assistant") {
                        if (!nextMessage.lines) {
                            nextMessage.lines = [];
                        } else {
                            const existingActionNames = new Set(nextMessage.lines.map((line) => line.action_name));
                            actions = actions.filter((action) => !existingActionNames.has(action.action_name));

                            if (actions.length === 0) {
                                processedMessages.add(j);
                                continue;
                            }
                        }

                        // Push the copied actions to the next assistant message
                        nextMessage.lines.push(...actions);
                        break;
                    }
                }
            }

            return _messages;
        },

        handleMinimizeWidgets({ exclude }) {
            this.$sendEvent(CHAT_EVENT.MINIMIZE_WIDGETS, { exclude_list: exclude });

            for (let message of this.messages) {
                for (let line of message.lines) {
                    if (lineIsWidget(line) && !exclude.includes(line.action_name) && !line.action_state.minimized) {
                        this.updateActionState({
                            message_id: message.chat_message_id,
                            lineIdx: message.lines.indexOf(line),
                            action_state: {
                                minimized: true,
                            },
                        });
                    }
                }
            }
        },
        handleWidgetFocus() {
            if (!this.showWidgetSidebar) {
                this.showWidgetSidebar = true;
            }
        },
        clearLoadingMessageTimeout() {
            if (this.startLoadingMessageTimeout !== null) {
                window.clearTimeout(this.startLoadingMessageTimeout);
                this.startLoadingMessageTimeout = null;
            }
        },
        handleLoadingMessageComplete() {
            /*
             * If loading message has reached to completion and we have received
             * a message through the socket, hide the loading message.
             *
             * Otherwise, probe at an interval until a message has been received.
             */
            if (this.firstSocketMessageReceived) {
                this.showStartLoadingMessage = false;
                return;
            }

            window.setTimeout(this.handleLoadingMessageComplete, START_LOADING_MESSAGE_THRESHOLD_MS);
        },
        handleVisibility() {
            if (document.visibilityState === "hidden") {
                logUserInteraction("backgrounded_tab");
                this.backgrounded_at = new Date();
            } else {
                logUserInteraction("resumed_tab");
                let resumed_at = new Date();
                let min_since_active = (resumed_at - this.backgrounded_at) / 1000 / 60;

                // If the user has been away more than 8 hours, reload the chat.
                if (min_since_active > 8 * 60) {
                    document.location.reload();
                }
            }
        },
        handleUnload() {
            logUserInteraction("closed_tab");
        },
        handleFirstSocketMessage() {
            this.firstSocketMessageReceived = true;
            /*
             * Clear the loading message threshold in case we received a
             * message through the socket before the loading message timeout
             * runs. This avoids showing the loading message unnecessarily.
             */
            this.clearLoadingMessageTimeout();
        },
        handleSocketOpened() {
            /*
             * Set a timeout to show the start chat loading message.
             * The intent is to show the loading message only after the waiting threshold has
             * been crossed. Once the loading message is shown, it is shown to completion
             * and then the message is shown if it has been received.
             */
            if (this.is_start_loading_message_enabled && this.startLoadingMessageTimeout === null) {
                this.startLoadingMessageTimeout = window.setTimeout(() => {
                    if (!this.firstSocketMessageReceived) {
                        this.showStartLoadingMessage = true;
                    }
                    this.startLoadingMessageTimeout = null;
                }, START_LOADING_MESSAGE_THRESHOLD_MS);
            }
        },
        handleChatInit(data) {
            this.raw_messages = data.data.messages;
            this.numberOfMessagesAtInit = data.data.messages.length;
            this.messages = this.bufferUserActionsToAssistantMessages(this.raw_messages);
            this.scrollToBottom();

            // Initialize role play adjustment if last message has a
            // role_play_adjustment action.
            if (this.raw_messages.length > 0) {
                const lastRolePlayAdjust = pluckAction([this.raw_messages[this.raw_messages.length - 1]], ACTION.ROLE_PLAY_ADJUST, true);
                if (lastRolePlayAdjust && lastRolePlayAdjust?.line.action_params?.adjustment) {
                    this.rolePlayAdjust = lastRolePlayAdjust.line.action_params.adjustment;
                }
            }
        },
        handlePromptShortcutKeyDown(event) {
            // allows a shortcut for valence users to switch into the role play prompt in demos
            if (event.key === "1" && event.ctrlKey) {
                this.promptSwitchCallCode = "Role_Play";
            }
        },
        handleTextboxShown() {
            this.has_taken_action = true;
            this.dismissSuggestedScenarios = true;
        },
        getMessageById(message_id, temp_id) {
            for (let m of this.raw_messages) {
                // TODO: Clean up the keys here
                if (m.id == message_id || m.message_id == message_id || m.chat_message_id == message_id) {
                    return m;
                }
                if (temp_id && (m.temp_id == temp_id || m.message_id == temp_id)) {
                    return m;
                }
            }
            return false;
        },
        getMessageLine(message_id, lineIdx) {
            const message = this.getMessageById(message_id);

            if (message) {
                return message.lines[lineIdx];
            }

            return false;
        },
        handleChatMessage({ data }) {
            // Do whatever UI state needed, add to message queue
            if (this.waiting_for_assistant) {
                this.clearSlowResponseTimeout();
            }

            const m = this.getMessageById(data.message_id, data.temp_id);

            if (m) {
                m.lines = data.lines;
                if (data.message_id) {
                    m.id = data.message_id;
                }
                if (data.chat_message_id) {
                    m.chat_message_id = data.chat_message_id;
                }
            } else {
                this.raw_messages.push(data);
            }

            this.messages = this.bufferUserActionsToAssistantMessages(this.raw_messages);

            if (!data.initial && data.role === "assistant") {
                this.waiting_for_assistant = false;
                this.waiting_for_action = false;
                logUserInteraction("coach_message_ended", { chat_message_id: data.chat_message_id });
                this.scrollToBottom();
            }

            this.checkForInChatFollowUp([data]);
        },
        handleChatPartialAddition(data) {
            this.waiting_for_assistant = true;
            this.clearSlowResponseTimeout();

            const m = this.getMessageById(data.data["message_id"]);

            if (m) {
                // Handling partial content
                m.lines = data.data["lines"];
            } else {
                this.raw_messages.push(data.data);
                logUserInteraction("coach_message_start");
            }

            this.scrollToBottom();
            this.startSlowResponseTimeout();
            this.messages = this.bufferUserActionsToAssistantMessages(this.raw_messages);
        },
        handleTranscriptionEvent(transcription) {
            if (!this.waiting_for_transcript) {
                return;
            }

            this.waiting_for_transcript = false;
            this.show_slow_transcription = false;
            this.dictated_in_this_message = true;
            logUserInteraction("transcription_ended");
            this.sendMessage(transcription, { promptSwitchCallCode: this.promptSwitchCallCode, rolePlayAdjust: this.rolePlayAdjust });
            this.promptSwitchCallCode = null;
            this.rolePlayAdjust = null;
        },
        cancelTranscription() {
            this.waiting_for_transcript = false;
        },
        updateActionState(data) {
            const { action_state, action_params, message_id, lineIdx, ...rest } = data;
            const line = { ...this.getMessageLine(message_id, lineIdx) };

            if (action_state) {
                line.action_state = {
                    ...line.action_state,
                    ...action_state,
                };
            }

            if (action_params) {
                line.action_params = {
                    ...line.action_params,
                    ...action_params,
                };
            }

            for (let x in rest) {
                line[x] = rest[x];
            }

            this.raw_messages = this.raw_messages.map((message) => {
                if (message_id === message.chat_message_id) {
                    return {
                        ...message,
                        lines: message.lines.map((l, idx) => {
                            if (idx === lineIdx) {
                                return line;
                            } else {
                                return l;
                            }
                        }),
                    };
                } else {
                    return message;
                }
            });
            this.messages = this.bufferUserActionsToAssistantMessages(this.raw_messages);
        },
        handleChooseAnswer(data) {
            this.updateActionState(data);
            this.$sendEvent("action", { data: { type: "answers", payload: data } });
            this.sendMessage(data.answer, {});
        },
        handleChooseScenario(data) {
            this.updateActionState(data);
            this.sendMessage(`Can we talk about this? "${data.answer}"`, { skipStream: true });
            this.$sendEvent("action", { data: { type: "scenarios", payload: data } });
        },
        handleFollowupChange(data) {
            this.updateActionState(data);
            this.$sendEvent("action", { data: { type: "followup", payload: data } });
        },
        handleChooseFollowup(data) {
            this.updateActionState(data);
            this.$sendEvent("action", { data: { type: "followup", payload: data } });
            this.markOnboardChecklistItemComplete(ONBOARDING_TASK.FOLLOWUP);
        },
        handleCoachableBulletPointsCoachMe(data) {
            this.sendMessage(`Can you coach me on this? "${data.action_params.coach_me.content}"`, {});
            this.$sendEvent("action", { data: { type: data.multiSelect ? "coachable_bullet_points_multiselect" : "coachable_bullet_points", payload: data } });
        },
        handleCoachableBulletPointsSaveForLater(data) {
            this.$sendEvent("action", { data: { type: "coachable_bullet_points", payload: data } });
        },
        handleTipAction(data) {
            this.updateActionState(data);
            this.$sendEvent("update_action", { data: { type: "tip", payload: data } });
        },
        handleInternalThinking(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "internal_thinking", payload } });
        },
        handleValuesInsight(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "values_insight", payload } });
        },
        handlePersonalizedAdvice(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "personalized_advice", payload } });
        },
        handlePersonalizedRating(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "personalized_rating", payload } });
        },
        handleRolePlayAdjust(payload) {
            if (payload.action_params?.adjustment) {
                this.rolePlayAdjust = payload.action_params.adjustment;
            }
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: ACTION.ROLE_PLAY_ADJUST, payload } });
        },
        handleSuggestedTopics(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "suggested_topics", payload } });
        },
        handleOnboardingSummary(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "osummary", payload } });
        },
        handleProfileQuestion(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "profile_question", payload } });
        },
        handleActionItems(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "action_items", payload } });
        },
        handleLLMExplanationFeedback(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "llm_explanation", payload } });
        },
        handleSendMessage({ content, files }) {
            this.sendMessage(content, { promptSwitchCallCode: this.promptSwitchCallCode, rolePlayAdjust: this.rolePlayAdjust, files });
            this.promptSwitchCallCode = null;
            this.rolePlayAdjust = null;
        },
        handleInferredAnswer(payload) {
            this.updateActionState(payload);
            this.$sendEvent("action", { data: { type: "inferred_profile_answer", payload } });
        },
        handleOnboardingFollowup() {
            this.markOnboardChecklistItemComplete(ONBOARDING_TASK.FOLLOWUP);
        },
        handleProfileNotification(payload) {
            this.updateActionState(payload);
            // Main Menu actively listens for this event
            document.dispatchEvent(new CustomEvent("valence:profileNotificationChange", { detail: payload }));
        },
        handleWrapUpChat() {
            logUserInteraction("wrap_up_chat");
            this.emitter.emit(CHAT_EVENT.OPEN_SESSION_SUMMARY);
            this.emitter.emit(CHAT_EVENT.MINIMIZE_WIDGETS, { exclude: [ACTION.ACTION_ITEMS] });
        },
        sendMessage(content, { promptSwitchCallCode = null, rolePlayAdjust = null, skipStream = false, files = [] } = {}) {
            if (content.length > 0 || files.length > 0 || hidden) {
                this.waiting_for_assistant = true;
                this.has_taken_action = true;
                // Temporary message so the backend can send back data about this message
                const temp_id = `${Date.now()}${this.cs_id}`;
                const message = {
                    temp_id: temp_id,
                    content,
                    role: "user",
                    dictation_on: this.dictationOn ?? false,
                    coaching_session_id: this.cs_id,
                    dictated: this.dictated_in_this_message,
                    skip_stream: skipStream,
                    files,
                };
                if (promptSwitchCallCode) {
                    message.prompt_switch_call_code = promptSwitchCallCode;
                }
                if (rolePlayAdjust) {
                    message.role_play_adjust = rolePlayAdjust;
                }

                this.$sendEvent("user_chat_message", message)
                    .then(() => {
                        message.lines = [{ type: "md", content }];
                        this.messages.push(message);
                        this.dictated_in_this_message = false;
                        this.scrollToBottom();
                        logUserInteraction("user_message_sent", {
                            coaching_session_id: this.cs_id,
                        });

                        this.emitter.emit(CHAT_EVENT.SET_MESSAGE_TEXT, "");

                        const userMessagesCount = this.messages.filter((message) => message.role === "user").length;
                        if (userMessagesCount === 3) {
                            this.markOnboardChecklistItemComplete(ONBOARDING_TASK.GUIDANCE);
                        }

                        if (!skipStream) {
                            this.startSlowResponseTimeout(4000);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.waiting_for_assistant = false;
                        this.chatError = CHAT_ERROR.SEND_FAILURE;
                        this.emitter.emit(CHAT_EVENT.SET_MESSAGE_TEXT, content);
                    });
            }
        },
        handleKeyDown() {
            if (this.chatError) {
                this.chatError = null;
            }
        },
        async transcribeRecording({ blob, recordingTime }) {
            const eventData = {
                transcribe_session_start: Date.now(),
                blob_size: blob.size,
                recording_time: recordingTime,
                user_agent: navigator.userAgent,
            };

            let encodedAudio;
            try {
                encodedAudio = await encodeAudio(blob);
            } catch (err) {
                logUserInteraction("recording_reader_failed", eventData);
                if ("Sentry" in window) {
                    window.Sentry.captureException(new Error("Recording reader failed"));
                }
                this.chatError = CHAT_ERROR.RECORDING_DEFAULT;
                return;
            }

            this.waiting_for_transcript = true;

            // Demoing feature!
            // Check if we have any remaining items to go through in our dev tools script
            if (this.devtoolsEnabled) {
                const chatScriptStorageKey = `chat_script_${this.first_prompt_id}`;
                const script = JSON.parse(window.localStorage.getItem(chatScriptStorageKey, "{}"));
                const messages = script?.messages || [];

                const messagesExist = messages.some((message) => message.message.length > 0);

                // Script index needs to be unique to the coaching session
                const scriptIndexKey = `chat_script_current_index_${this.cs_id}`;
                const currentScriptIndex = Number(window.localStorage.getItem(scriptIndexKey, 0));

                if (messagesExist && currentScriptIndex < messages.length) {
                    const currentMessage = messages[currentScriptIndex].message;
                    window.localStorage.setItem(scriptIndexKey, currentScriptIndex + 1);

                    // Wait between 300 and 700ms to make it look like we're actually doing some work here
                    setTimeout(
                        () => {
                            this.sendMessage(currentMessage);
                            this.waiting_for_transcript = false;
                        },
                        getRandomInt(300, 700),
                    );

                    return;
                }
            }

            try {
                const transcript = await transcribeRecording({
                    encodedAudio,
                    sendEvent: this.$sendEvent,
                    onStart: () => {
                        this.show_slow_transcription = false;
                        logUserInteraction("transcription_request_start", eventData);
                    },
                    onSlow: () => {
                        this.show_slow_transcription = true;
                        logUserInteraction("saw_slow_transcription_message");
                    },
                    onError: () => {
                        logUserInteraction("saw_transcription_error_message");
                        logUserInteraction("transcription_request_failed", eventData);
                        if ("Sentry" in window) {
                            window.Sentry.captureException(new Error("Transcription request failed"));
                        }
                    },
                });
                logUserInteraction("transcription_request_succeeded", eventData);
                this.handleTranscriptionEvent(transcript);
            } catch {
                this.chatError = CHAT_ERROR.TRANSCRIPTION_EXHAUSTED;
                logUserInteraction("transcription_request_retries_exhausted", eventData);
            } finally {
                this.waiting_for_transcript = false;
                this.show_slow_transcription = false;
            }
        },
        handleRecordingComplete(recording) {
            this.markOnboardChecklistItemComplete(ONBOARDING_TASK.MIC);
            this.transcribeRecording(recording);
        },
        handleRecordingError() {
            this.chatError = CHAT_ERROR.RECORDING_DEFAULT;
        },
        handleRecordingStart() {
            this.chatError = null;
            this.dismissSuggestedScenarios = true;
            this.has_taken_action = true;
        },
        scrollToBottom() {
            setTimeout(() => {
                if (!this.$refs.chatMessageControls || !this.$refs.chatMessageList) {
                    return;
                }
                const messageControlsHeight = this.$refs.chatMessageControls.getControlsHeight();
                const chatGroupElement = this.$refs.chatMessageList.getChatGroupElement();
                const chatBottom = this.$refs.chatMessageList.getChatBottom();

                let lastCoachElement = null;
                let elements = document.querySelectorAll(".chat-group .chat-message.coach");
                if (elements.length > 0) {
                    lastCoachElement = elements[elements.length - 1];
                }

                let appHeight = document.getElementById("app").clientHeight;
                let lastCoachElementHeight = 0;
                if (lastCoachElement) {
                    lastCoachElementHeight = lastCoachElement.clientHeight;
                }

                let lastUserElement = null;
                let lastUserElementAnchor = null;
                let lastUserElementHeight = 0;
                elements = document.querySelectorAll(".chat-group .chat-message.user");
                if (elements.length > 0) {
                    lastUserElement = elements[elements.length - 1];
                    lastUserElementHeight = lastUserElement.clientHeight;
                }
                elements = document.querySelectorAll(".chat-group .chat-message.user .chat-anchor");
                if (elements.length > 0) {
                    lastUserElementAnchor = elements[elements.length - 1];
                }

                // Chat + header and footer
                if (appHeight > chatGroupElement.clientHeight + 140) {
                    if (chatGroupElement) {
                        chatGroupElement.style.paddingBottom = messageControlsHeight + "px";
                    }
                    if (chatBottom) {
                        chatBottom.scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                    return;
                }

                if (lastCoachElement && lastCoachElementHeight + lastUserElementHeight > appHeight - 90) {
                    if (chatGroupElement) {
                        chatGroupElement.style.paddingBottom = messageControlsHeight + 60 + "px";
                    }
                    if (chatBottom) {
                        chatBottom.scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                } else {
                    if (lastUserElement) {
                        if (chatGroupElement) {
                            chatGroupElement.style.paddingBottom = messageControlsHeight + appHeight - lastUserElementHeight - lastCoachElementHeight - 180 + "px";
                        }
                    } else {
                        if (chatGroupElement) {
                            chatGroupElement.style.paddingBottom = messageControlsHeight + appHeight - lastCoachElementHeight + "px";
                        }
                    }
                    if (lastUserElementAnchor) {
                        lastUserElementAnchor.scrollIntoView({ behavior: "smooth", block: "start" });
                    }
                }
            }, 200);
        },
        startSlowResponseTimeout(timeout_ms = SLOW_MESSAGE_TIMEOUT_MS) {
            this.clearSlowResponseTimeout();

            this.show_slow_response = false;
            this.slow_response_timeout = setTimeout(
                function () {
                    this.startFailedResponseTimeout();
                    this.show_slow_response = true;
                    logUserInteraction("saw_slow_message");
                }.bind(this),
                timeout_ms,
            );
        },
        clearSlowResponseTimeout() {
            this.clearFailedResponseTimeout();
            this.show_slow_response = false;
            clearTimeout(this.slow_response_timeout);
        },
        startFailedResponseTimeout(timeout_ms = FAILED_MESSAGE_TIMEOUT_MS) {
            this.clearFailedResponseTimeout();

            this.failed_response_timeout = setTimeout(
                function () {
                    this.show_failed_response = true;
                    logUserInteraction("saw_failed_message");
                }.bind(this),
                timeout_ms,
            );
        },
        clearFailedResponseTimeout() {
            this.show_failed_response = false;
            clearTimeout(this.failed_response_timeout);
        },
        markOnboardChecklistItemComplete(taskId) {
            this.emitter.emit(CHAT_EVENT.MARK_ONBOARDING_TASK, {
                taskId,
            });
        },
        checkForInChatFollowUp(messages) {
            if (!Array.isArray(messages)) {
                return;
            }
            for (const message of messages) {
                if (!Array.isArray(message.lines)) {
                    continue;
                }
                for (const [lineIdx, line] of message.lines.entries()) {
                    if (line.action_name === "followup" && line.action_params && !line.action_params.event_at_confirmed) {
                        this.inChatFollowUp = { line, messageId: message.chat_message_id, role: message.role, lineIdx };
                        this.showInChatFollowUp = true;
                        break;
                    }
                }
            }
        },
        handleToggleWidgetSidebar() {
            this.showWidgetSidebar = !this.showWidgetSidebar;
        },
        handleCoachingModeUpdate(payload) {
            document.dispatchEvent(new CustomEvent("coaching_mode_updated", { detail: payload }));
        },
        async handleUserSwitchedCoachingMode(payload) {
            await this.$sendEvent("action", {
                data: {
                    type: "coaching_mode",
                    payload: {
                        requested_coaching_mode: payload.newCoachingMode,
                        switch_message: payload.switchMessage,
                        websocket_channel_id: this.ws_url,
                    },
                },
            });
            logUserInteraction("switched_coaching_mode", {
                switched_from: payload.oldCoachingMode,
                switched_to: payload.newCoachingMode,
            });
        },
        handleTeamMemberContext() {
            useChatStore.setHasTeamMemberContext(true);
        },
    },
};
</script>
