<template>
    <ChatWidgetFrame class="mt-4 rounded-3xl border shadow" theme="white" title="">
        <template #widget-title>
            <p class="text-lg font-bold text-valence-grey-400 tracking-widest uppercase">Pick up where you left off!</p>
        </template>
        <div class="flex flex-col items-left gap-4">
            <ul v-for="focusArea in data.action_params.focus_areas" class="flex flex-col mx-w-md">
                <p class="text-left text-lg capitalize">{{ focusArea.title }}</p>
                <p class="text-gray-400 text-left">
                    <span>{{ focusArea.percentage_completed }}% Complete</span>
                </p>
                <div class="">
                    <button
                        class="px-6 rounded-3xl py-3 text-center leading-none font-semibold border text-white bg-valence-pink-bright hover:bg-valence-pink-600 capitalize w-full sm:w-auto"
                        @click="selectFocusArea(focusArea)"
                    >
                        Continue Chatting
                    </button>
                </div>
            </ul>
        </div>
    </ChatWidgetFrame>
</template>

<script setup>
import { defineProps } from "vue";

import ChatWidgetFrame from "./ChatWidgetFrame.vue";

const props = defineProps({
    data: Object({}),
    theme: String,
});

const selectFocusArea = (focusArea) => {
    window.location.href = focusArea.url;
};
</script>
