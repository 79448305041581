<template>
    <ChatWidgetFrame theme="blue" icon="bi-chat-dots" :title="data.action_params.title" :minimized="isMinimized" @widget-toggle-visibility="toggleVisibility">
        <ChatWidgetText class="text-sm" :content="data.action_params.content" />
        <template v-if="data.action_params.link">
            <a
                class="text-center py-3 leading-none border border-2 hover:bg-valence-grey-100 active:bg-valence-grey-200 h-auto w-full text-sm rounded-xl"
                :href="data.action_params.link"
                target="_blank"
                @click="submit"
            >
                {{ data.action_params.link_text }}
            </a>
        </template>
    </ChatWidgetFrame>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject, ref } from "vue";

import ChatWidgetFrame from "./ChatWidgetFrame.vue";
import ChatWidgetText from "./ChatWidgetText.vue";

const props = defineProps({
    data: Object({}),
    role: String("user"),
    messageId: Number(0),
    lineIdx: Number(0),
});

const { emitter } = inject("globalProperties");

const isMinimized = ref(false);

function toggleVisibility() {
    isMinimized.value = !isMinimized.value;

    emitter.emit("customizable_text", {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_state: {
            minimized: isMinimized.value,
        },
    });

    logUserInteraction("customizable_text_minimize", { minimized: isMinimized.value });
}

function submit() {
    isMinimized.value = true;
    emitter.emit("customizable_text", {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_state: {
            submitted: true,
            minimized: true,
        },
    });

    logUserInteraction("customizable_text_submitted");
}
</script>
