<template>
    <div class="relative">
        <button type="button" title="Select mode" class="text-left items-start w-full rounded-md text-md font-medium text-gray-700" @click="toggleDropdown">
            <span id="mode-options-menu" class="text-xs text-gray-400 uppercase font-light tracking-wide w-full">Role Play Mode</span>
            <div class="flex items-center w-full gap-2">
                <span class="font-extrabold">{{ currentLabel }}</span>
                <i class="bi bi-chevron-down h-5 w-5"></i>
            </div>
        </button>
        <div v-if="isOpen" class="origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
            <ul class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="mode-options-menu">
                <li v-for="option in options" :key="option[0]" role="menuitem">
                    <button type="button" class="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" @click="setAdjustment(option[0])">
                        {{ option[1] }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";

const isOpen = ref(false);
const emit = defineEmits(["selection"]);

const props = defineProps({
    value: {
        type: String,
        default: () => null,
    },
    options: {
        type: Array,
        default: () => [],
    },
});

const currentLabel = computed(() => {
    if (props.value) {
        const mode = props.options.find(([value]) => props.value === value);
        if (mode) {
            return mode[1];
        }
    }

    return null;
});

function toggleDropdown() {
    isOpen.value = !isOpen.value;
}

function setAdjustment(mode) {
    isOpen.value = false;
    emit("selection", mode);
}
</script>
