<template>
    <Transition name="fade">
        <ChatNotificationMessage v-if="!line.action_state.dismissed" custom>
            <div class="flex gap-2">
                <span class="h-5">
                    <CheckmarkInCircle />
                </span>
                <div>
                    <span>I&apos;ve emailed you an invite for us to check in&nbsp;</span>
                    <span v-if="line.action_params.recurring" class="font-semibold">every {{ eventDay }}.</span>
                    <span v-else
                        >on <span class="font-semibold">{{ eventDay }}.</span>
                    </span>
                    <span>
                        <span>&nbsp; Ready to go?&nbsp;</span>
                        <button type="button" class="inline text-left hover:text-valence-violet-800/75 underline" @click="emitter.emit('wrap_up_chat')">
                            Wrap up this conversation
                        </button>
                        <span>&nbsp;now.</span>
                    </span>
                </div>
            </div>
        </ChatNotificationMessage>
    </Transition>
</template>

<script setup>
import { DateTime } from "luxon";
import { computed, defineProps, inject } from "vue";

import ChatNotificationMessage from "./ChatNotificationMessage.vue";
import CheckmarkInCircle from "./icons/CheckmarkInCircle.vue";

const props = defineProps({
    line: Object,
});

const { emitter } = inject("globalProperties");

const eventDay = computed(() => DateTime.fromISO(props.line.action_params.event_at_confirmed).toLocal().toLocaleString({ weekday: "long" }));
</script>
