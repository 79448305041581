<template>
    <div
        v-if="!dismissed"
        class="relative mx-3 my-6 text-valence-violet-800 bg-valence-violet-50 border-valence-violet-800 border rounded-lg p-4 leading-none flex flex-row items-center gap-3"
    >
        <div v-if="line" class="w-5 h-5">
            <template v-if="line.action_name === action.TIP">
                <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66667 1.83545L2 9.83545H8L7.33333 15.1688L14 7.16878H8L8.66667 1.83545Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </template>
            <template v-else-if="line.action_name === action.SUGGESTED_TOPICS">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 5.33333L14.06 9.50667L18.6667 10.18L15.3333 13.4267L16.12 18.0133L12 15.8467L7.88001 18.0133L8.66668 13.4267L5.33334 10.18L9.94001 9.50667L12 5.33333Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </template>
            <template v-else-if="line.action_name === action.ACTION_ITEMS">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 11.3333L12 13.3333L18.6667 6.66666" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M18 12V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V7.33333C6 6.97971 6.14048 6.64057 6.39052 6.39052C6.64057 6.14048 6.97971 6 7.33333 6H14.6667"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </template>
        </div>
        <div v-if="line && line.action_name === action.TIP" class="*:font-medium *:text-sm" v-html="renderMarkdown(line.action_params?.content)"></div>
        <p v-else class="font-medium text-sm">
            <template v-if="custom">
                <slot></slot>
            </template>
            <template v-if="line && line.action_name === action.SUGGESTED_TOPICS">
                I&apos;ve come up with a coaching topic for next session.
                <button type="button" class="inline text-left hover:text-valence-violet-800/75 underline" @click="focusWidget">Click here to schedule time.</button>
            </template>
            <template v-if="line && line.action_name === action.ACTION_ITEMS">
                I&apos;ve compiled action items for you.
                <button type="button" class="inline text-left underline hover:text-valence-violet-800/75" @click="focusWidget">Click here to send yourself a summary.</button>
            </template>
        </p>
        <i class="bi bi-x absolute right-2 top-2 cursor-pointer" @click="dismiss"></i>
    </div>
</template>

<script>
import { logUserInteraction } from "~vue/utils/logUtils.js";
import DOMPurify from "dompurify";
import { marked } from "marked";

import { ACTION, lineIsAction } from "./chatActions.js";
import { CHAT_EVENT } from "./events.js";

const SUPPORTED_ACTIONS = [ACTION.ACTION_ITEMS, ACTION.SUGGESTED_TOPICS, ACTION.TIP];

export function lineHasNotification(line) {
    return lineIsAction(line) && SUPPORTED_ACTIONS.includes(line.action_name);
}

export default {
    name: "ChatNotificationMessage",
    props: {
        messageId: {
            type: Number,
        },
        lineIdx: {
            type: Number,
        },
        line: {
            type: Object,
        },
        custom: {
            type: Boolean,
        },
    },
    data() {
        return {
            dismissed: false,
        };
    },
    computed: {
        action() {
            return ACTION;
        },
    },
    mounted() {
        if (this.line && this.line.action_name === ACTION.TIP) {
            logUserInteraction("tip_presented", {
                chat_message_id: this.messageId,
                tip_type: this.line.action_params?.tip_type,
                content: this.line.action_params?.content,
            });
        }
    },
    methods: {
        renderMarkdown(content = "") {
            const replaced = content.replaceAll("\n\n", "\n").replaceAll("```", "");
            return DOMPurify.sanitize(marked.parse(replaced, { headerIds: false, mangle: false }));
        },
        focusWidget() {
            this.emitter.emit(CHAT_EVENT.FOCUS_WIDGET, { messageId: this.messageId, lineIdx: this.lineIdx, actionName: this.line.action_name });
        },
        dismiss() {
            this.dismissed = true;
        },
    },
};
</script>
