<template>
    <ChatWidgetFrame theme="blue" title="Should we follow up?" icon="bi-calendar2-event" :minimized="data.action_state.minimized" @widget-toggle-visibility="toggleVisibility">
        <p v-if="reschedule_override || actionIsActionable" class="text-sm">It looks like we should follow up on this. What time works for you?</p>
        <p v-else-if="!actionIsActionable" class="text-sm">
            Follow-up scheduled for <strong class="font-semibold">{{ formattedFollowupDate() }}</strong
            >. An invitation is on its way to your inbox right now.
        </p>
        <template v-if="reschedule_override || actionIsActionable">
            <ScheduleFollowUp :date="date" :recurring="data.action_params.recurring" @date-update="onDateUpdate" @recurring-update="onRecurringUpdate" />
            <textarea
                id="invite_description"
                v-model="inviteDescription"
                placeholder="Write follow up agenda here"
                class="outline-none rounded text-sm p-2"
                name="invite_description"
                rows="5"
            ></textarea>
            <Button @click="scheduleFollowup"> Schedule </Button>
        </template>
        <Button v-else-if="!actionIsActionable" @click="reschedule"> Reschedule </Button>
    </ChatWidgetFrame>
</template>

<script>
import Button from "~vue/components/BaseButton.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { ref } from "vue";

import ChatWidgetFrame from "./ChatWidgetFrame.vue";
import ScheduleFollowUp from "./ScheduleFollowUp.vue";

export default {
    name: "ChatActionFollowupInSidebar",
    components: {
        ScheduleFollowUp,
        ChatWidgetFrame,
        Button,
    },
    props: {
        data: Object({}),
        role: String("user"),
        messageId: Number(0),
        lineIdx: Number(0),
    },
    setup(props) {
        const reschedule_override = ref(false);
        const inviteDescription = ref(props.data.action_params.invite_description ?? "");
        const date = ref(new Date(props.data.action_params.event_at_confirmed ?? props.data.action_params.event_at_guessed));
        const recurring = ref(props.data.action_params.recurring);
        return {
            inviteDescription,
            reschedule_override,
            date,
            recurring,
        };
    },
    computed: {
        actionIsActionable() {
            return !this.data.action_state.submitted;
        },
    },
    mounted() {
        logUserInteraction("followup_presented", { chat_message_id: this.messageId });
        if (this.data.action_state.minimized) {
            this.reschedule_override = true;
        }
    },
    methods: {
        onDateUpdate(newDate) {
            this.date = newDate;
        },
        onRecurringUpdate(recurring) {
            this.recurring = recurring;
        },
        scheduleFollowup() {
            const action_data = {
                message_id: this.messageId,
                lineIdx: this.lineIdx,
                action_params: {
                    calendar_event_id: this.data.action_params.calendar_event_id,
                    invite_description: this.inviteDescription,
                    event_at_confirmed: DateTime.fromJSDate(this.date).toISO(),
                    recurring: this.recurring,
                },
                action_state: {
                    submitted: true,
                },
            };
            this.reschedule_override = false;
            this.emitter.emit("choose_followup", action_data);
            logUserInteraction("followup_scheduled");
        },
        toggleVisibility() {
            this.reschedule_override = !this.data.action_state.minimized;
            this.emitter.emit("choose_followup", {
                message_id: this.messageId,
                lineIdx: this.lineIdx,
                action_state: {
                    minimized: !this.data.action_state.minimized,
                },
            });
            logUserInteraction("followup_minimize", { minimized: this.data.action_state.minimized });
        },
        reschedule() {
            this.reschedule_override = true;
        },
        formattedFollowupDate() {
            return DateTime.fromJSDate(this.date).toLocaleString(DateTime.DATETIME_FULL);
        },
    },
};
</script>
