<template>
    <ChatWidgetFrame theme="blue" title="My memory of you" icon="bi-person-vcard" :minimized="minimized" @widget-toggle-visibility="toggleVisibility()">
        <template #tag>
            <div class="ml-9 -mt-4">
                <TextTag tint="darkgrey" class="inline-block">{{ insightsNum }} insights</TextTag>
            </div>
        </template>
        <div v-for="(item, idx) in insightsArray" :key="idx">
            <div class="first:mt-0 mt-2 flex items-center justify-between gap-3">
                <p class="font-semibold">{{ item.profile_question_key }}</p>
                <button type="button" class="py-2 px-2 rounded-xl text-md" @click="showDeletionDialog(item.id)">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 4.50195H3.33333H14" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M5.33337 4.50163V3.16829C5.33337 2.81467 5.47385 2.47553 5.7239 2.22549C5.97395 1.97544 6.31309 1.83496 6.66671 1.83496H9.33337C9.687 1.83496 10.0261 1.97544 10.2762 2.22549C10.5262 2.47553 10.6667 2.81467 10.6667 3.16829V4.50163M12.6667 4.50163V13.835C12.6667 14.1886 12.5262 14.5277 12.2762 14.7778C12.0261 15.0278 11.687 15.1683 11.3334 15.1683H4.66671C4.31309 15.1683 3.97395 15.0278 3.7239 14.7778C3.47385 14.5277 3.33337 14.1886 3.33337 13.835V4.50163H12.6667Z"
                            stroke="#9A9A9A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path d="M9.33337 7.83496V11.835" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.66663 7.83496V11.835" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
            </div>
            <p class="text-xs">{{ item.value }}</p>
        </div>
    </ChatWidgetFrame>
    <GenericDiaglog ref="inferenceDialog" action-button-label="Forget" :action-button-callback="forgetInference">
        <template #content>
            <p class="text-lg text-center mx-10">
                Nadia will forget your <strong class="text-lg font-bold">{{ insightToForget }}</strong
                >. This cannot be undone.
            </p>
        </template>
    </GenericDiaglog>
</template>

<script setup>
import GenericDiaglog from "~vue/components/GenericDialog.vue";
import TextTag from "~vue/components/TextTag.vue";
import { CHAT_EVENT } from "~vue/events.js";
import { computed, defineEmits, inject, onMounted, ref } from "vue";

import { getCookie } from "../utils";
import ChatWidgetFrame from "./ChatWidgetFrame.vue";

const minimized = ref(true);
const insightsArray = ref([]);
const insightsNum = computed(() => insightsArray.value.length);
const insightToForget = ref(null);
const inferenceDialog = ref(null);

const emit = defineEmits(["insight-num-change"]);
const { emitter } = inject("globalProperties");

onMounted(() => {
    getProfileInferences();
    emitter.on("inferred_profile_answer", getProfileInferences);
    emitter.on(CHAT_EVENT.INFERENCE_MADE, getProfileInferences);
});

function getCsId() {
    return window.location.pathname.split("/")[2];
}

async function getProfileInferences() {
    const cs_id = getCsId();
    try {
        const response = await fetch(`/accounts/profile-data/cs/${cs_id}/`, {
            method: "GET",
            credentials: "include",
        });
        const responseData = await response.json();
        insightsArray.value = responseData;
        emit("insight-num-change", insightsNum.value);
    } catch (e) {
        if (window.Sentry) {
            window.Sentry.captureException(e);
        }
    }
}

function toggleVisibility() {
    minimized.value = !minimized.value;
}

function showDeletionDialog(id) {
    inferenceDialog.value.open();
    const item = insightsArray.value.find((item) => item.id === id);
    insightToForget.value = item.profile_question_key;
}

async function forgetInference() {
    if (!insightToForget.value) {
        inferenceDialog.value.dismiss();
        return;
    }

    const insightId = insightsArray.value.find((item) => item.profile_question_key === insightToForget.value).id;

    try {
        const response = await fetch(`/accounts/profile-data/${insightId}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });

        if (response.status === 204) {
            insightsArray.value = insightsArray.value.filter((item) => item.id !== insightId);
            insightToForget.value = null;
            inferenceDialog.value.dismiss();
            emit("insight-num-change", insightsNum.value);
        } else {
            throw new Error("Failed to delete inference");
        }
    } catch (e) {
        if (window.Sentry) {
            window.Sentry.caputreException(e);
        }
    }
}
</script>
